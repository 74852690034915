
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HelpComponent } from './help.component';




const routes: Routes = [{
  path:'',
  component:HelpComponent,
  children:[
    {
      path:'help',
      component:HelpComponent,
      pathMatch: 'full'
    } 
]
}];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class HelpRoutingModule { }
