import { Injectable } from '@angular/core';
import { CustomerDetailsApiResponse } from '../../types/container.type';
import { API_URL_RIDER_DETAILS } from 'src/app/config/apiRouteConfig';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class CustomerDetailsApiService {

  constructor(private _http: HttpClient) { }

  fetchCustomerDetails(contactDetails): Observable<CustomerDetailsApiResponse>{
    return this._http.get<CustomerDetailsApiResponse>(`${API_URL_RIDER_DETAILS}?phone=${contactDetails.inputValue}&callingCode=%2B${contactDetails.callingCodeValue.split("+")[1]}`)
  }

}
