import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { UsersService } from 'src/app/services/users/users.service';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
import { NgxSpinnerService } from "ngx-spinner";
import { AppService } from 'src/app/services/app.service';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css']
})
export class ResetPasswordComponent implements OnInit {

  @ViewChild('otpBox_1', {static: false}) otpBox1 : ElementRef | undefined;
  @ViewChild('otpBox_2', {static: false}) otpBox2 : ElementRef | undefined;
  @ViewChild('otpBox_3', {static: false}) otpBox3 : ElementRef | undefined;
  @ViewChild('otpBox_4', {static: false}) otpBox4 : ElementRef | undefined;
  @ViewChild('otpBox_5', {static: false}) otpBox5 : ElementRef | undefined;
  @ViewChild('otpBox_6', {static: false}) otpBox6 : ElementRef | undefined;


  @ViewChild('counter', {static:false}) counter : ElementRef | undefined;

  content:string | undefined;
  AccntLocked:boolean | undefined;
  enteredMailId:string  ='';
  resendMessage:string = 'Resend Code';
  disableVerifyBtn:boolean = true;
  disableProceedBtn:boolean = true;
  disableVerifyOtpBtn:boolean = true;
  emailValidation:boolean = true;
  enableVerificationMethod:boolean = false;
  enableOtp:boolean = false;
  selectedOtpOption:string = '';
  verificationMethodLabel:string[] | undefined;
  title:string | undefined;
  otpIndex:any;
  verifyOtpStatus:boolean = false;
  verifyStatusContent:string | undefined;
  verifyStatusBtnLabel:string | undefined;
  enteredOtp:any[] = [];
  count:number = 60;
  intervalId:any;
  counterMessage:any;
  sessionTimeOut:any = false;
  throughForgetPassword:any = false ;
  validationMessage:string | undefined;
  showBackBtn:boolean = true;
  accntLockStatus:boolean | undefined;
  resendOtpCount:number = 0;
  resendDisabled:boolean = true;
  attempts
// Api flag variables
  otpVerified:boolean | undefined;
  mobNo:string = '';
  email:string  = '';


  constructor(private _userService:UsersService, private activatedRouter: ActivatedRoute, private router: Router,private loader: NgxSpinnerService, private _app: AppService)
     { }

  ngOnInit() {
    if(localStorage.getItem('accessToken')){
      this.router.navigateByUrl('rides/liverides')
    }
    
    this.activatedRouter.queryParams.subscribe(params=>{
      this.AccntLocked = params['accntLocked']=='true'? true : false
      this.isAccntLocked(this.AccntLocked);
    },(error)=>{
      Swal.fire({
        icon: 'info',
        title: 'Technical Error!',
        text: 'Technical Error'
      });
    })
  }


  // checking email syntax is correct or not
  verifyEmailFormat(){
    this.emailValidation = true;
    this.disableVerifyBtn = this.enteredMailId.match('^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,}$')? false : true;
  }

  isAccntLocked(validation:boolean){
    if(!validation){
      this.content= "Reset password in 2 easy steps. To begin, Please enter your registered email ID";
      this.title = "Forgot Password?"; 
      this.throughForgetPassword = true;
      }
   else{
        this.attempts = this._app.getLoginAttempts();
        this.title = "Account Locked";  
        this.throughForgetPassword = false; 
      }
  }

  // checking email id is registered in database or not
  validateEmail (){
      
      this.loader.show();
      clearInterval(this.intervalId)
      if(this.enteredMailId == null || this.enteredMailId == ""){
        this.loader.hide();
        this.emailValidation = false;
        this.validationMessage = "Please enter your email Id";
      }

      else {
        if(this.disableVerifyBtn){
          this.loader.hide();
          this.emailValidation = false;
          this.validationMessage = "Please enter a valid email id";
        }

        else{
        this._userService.verifyUser({username : this.enteredMailId}).subscribe((response:any)=>{
        this.loader.hide(); 

        if (response['statusCode'] >= 300) {
          this.emailValidation = false;
          this.email = "";
          this.mobNo = "";
          this.content =  "Given Email ID is not verified. Kindly enter registered email ID";
          this.validationMessage = "Not a registered email id";
          this.enableVerificationMethod = false;
        }else {
          this.emailValidation = true;
          this.mobNo = response['response']['phone'];
          this.email = response['response']['email'];
          this.verificationMethodLabel = [`via Mobile Number (+91 ${this.mobNo})`, `via Email (${this.email})`];
          this.content =  "Email Verification has been successfully done!";
          this.enableOtp = false;
          this.enableVerificationMethod = true;
        }
        
    },(error)=>{
      Swal.fire({
        icon: 'info',
        title: 'Technical Error!',
        text: 'Technical Error'
      })
    })}}}


  // enabling box of methods for getting otp 
  checkVerificationMethod(){
    this.disableProceedBtn = false;
  }


// OTP template methods
  getOtp(resend:boolean){
    clearInterval(this.intervalId)
    this.count = 60;
    
    let payload = {
      username : this.enteredMailId, 
      notificationChannel : this.selectedOtpOption, 
      resendOtp : resend
    };
    this.loader.show();
    
    this._userService.sendOtp(payload).subscribe((res:any)=>{
      this.loader.hide();
      if(res['statusCode'] >= 300){
        Swal.fire({
          icon: 'info',
          title: 'Error',
          text: res['message']
        })
      }else{
        this.counterMessage = `${this.count} seconds remaining`;
        
        this.intervalId=setInterval(()=>{

          if(this.count > 0){
            this.counterMessage = `${this.count -= 1} seconds remaining`;
            this.sessionTimeOut = false;
            this.resendDisabled = true;
          }

          else{          
            this.counterMessage = `Session Timed Out`;
            this.sessionTimeOut = true;
            this.disableVerifyOtpBtn = true;
            if(this.selectedOtpOption == 'SMS'){
              this.resendDisabled = this.resendOtpCount > 1? true : false;
              this.resendMessage = this.resendOtpCount > 0 ?  'Resend code on call' : 'Resend Code'  ;
            }
            else{
              this.resendDisabled = false;
              this.resendMessage = 'Resend Code';
            }

            clearInterval(this.intervalId);
          }

        }, 1000);
    
        this.enableOtp  = true;
    
        if(this.selectedOtpOption == "SMS"){
        this.title = "We sent a code to your mobile number";
        this.content = `Enter the X-digit verification code sent to <br> <b>+91 ${this.mobNo}</b>.`;
        this.disableVerifyOtpBtn = true;
        }
    
        else if(this.selectedOtpOption == "EMAIL"){
          this.title = "We sent a code to your email";
          this.content = `Enter the X-digit verification code sent to <br><b>${this.email}<b>`; 
          this.disableVerifyOtpBtn = true;
        }
        
      }

    },(error) => {
      Swal.fire({
           icon: 'info',
           title: 'Technical Error!',
           text: 'Technical Error'
         })
    })

  }

  checkOtpBoxIndex(index:number){
    this.otpIndex = index;
  }

  changeOtpBox(event: any,){
    event.target.value = event.target.value[0];

    if(this.otpIndex<6){
      event.key == "Backspace" && this.otpIndex > 1 ? this.otpIndex -=1 : null;
      event.key.match('^[0-9]+$') && this.otpIndex > 0 && event.target.value != "" ? this.otpIndex +=1 :null;

      this[`otpBox${this.otpIndex}`].nativeElement.focus();
    }

    else if(this.otpIndex == 6){

      if(event.key == "Backspace" && this.otpIndex > 1){
        this.otpIndex -= 1;
        this[`otpBox${this.otpIndex}`].nativeElement.focus();
      }
      
      else if(event.target.value != ""){
        this[`otpBox${this.otpIndex}`].nativeElement.blur();
        this.disableVerifyOtpBtn = false;
      }  
    }
  }

  resendOtp(event){
    if(!this.sessionTimeOut){
      return "";
    }
    
    else{
      this.resendOtpCount += 1;

      clearInterval(this.intervalId);
      
      for(let i = 1 ; i < 7 ; i++){
      this[`otpBox${i}`].nativeElement.value = "";
      }

      if(this.selectedOtpOption == 'SMS'){        
        
        switch(this.resendOtpCount){
          case 1:
            this.getOtp(false);
            break;
          case 2:
            this.getOtp(true);
            break;
          default:
            this.count = 0;
        }
      }
      else{
        this.getOtp(false);  
      }
    }
  }

  // verifying entered otp is true or not
  verifyOtp(){
    this.enteredOtp=[];
    for(let i = 1 ; i < 7 ; i++){
      this.enteredOtp.push(this[`otpBox${i}`].nativeElement.value);
    }
    let payload ={username : this.enteredMailId, otp : this.enteredOtp.join("")}
    this.showBackBtn = false;
    this.loader.show();
    this._userService.verifyOtp(payload).subscribe(response=>{
      this.loader.hide();
      if(response['statusCode']>= 300){
        if(response['message'] ==  "OTP is invalid"){
          this.title = "Entered incorrect OTP";
          this.verifyStatusContent = "Your password has not been reset. Please retry";
          this.otpVerified =  false ; 
          this.verifyOtpStatus = true;
          this.enableOtp = false;
          this.content = null;
        }else{
          Swal.fire({
            title: 'Error',
            icon: 'info',
            text: response['message']
          });
        }
        
      }else {
        this.otpVerified =  true ; 
        this.verifyOtpStatus = true;
        this.enableOtp = false;
        this.content = null;
        this.title = "Password reset successfully";
      this.verifyStatusContent = "Your password has been successfully reset and a new password has been emailed to your registered email address";
      }
    
    },(error)=>{
      Swal.fire({
        icon: 'info',
        title: 'Technical Error!',
        text: 'Technical Error'
      })
    });
  }

  otpVerifyStatusAction(){
    if(this.otpVerified){
      this.router.navigate(['/login']);
    }

    else{
      this.verifyOtpStatus = false;
      this.enableVerificationMethod = true;
      this.showBackBtn = true;
      this.title = 'Forgot Password?';
      this.content = "Email Verification has been successfully done!";
    }
  }

  prevScreen(){
  
    // if otp typing screen is on
    if(this.enableOtp){
      this.resendOtpCount = 0;
      clearInterval(this.intervalId)
      this.enableOtp = false;
      this.enableVerificationMethod = true;
      this.content = 'Email Verification has been successfully done!';
      this.title = 'Forgot Password?';
    } 

    //if otp verification method screen is on
    else if(this.enableVerificationMethod){
      this.enableVerificationMethod = false;
      this.throughForgetPassword = true;
      this.content = "Reset password in 2 easy steps. To begin, Please enter your registered email ID";

    }
    

    //if email verification screen is on or account locaked screen is on
    else{
      if(this.AccntLocked){
        if(this.throughForgetPassword){
          this.throughForgetPassword = false;
          this.title = 'Account Locked';
          this.content = ""
        }
        else{
          this.router.navigateByUrl('/login');
        }
      }

      else{
        this.router.navigateByUrl('/login')
      }
    }
    
  }

}
