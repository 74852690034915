<div class="card-container-main" *ngIf="type == 'main'">
    <ng-content select="[main]"></ng-content>
</div>


<div class="card-container" *ngIf="type == 'container'"
[ngClass]="{'card-container-sub': !isActive, 'card-container-active': isActive && !isShowSelectionLabel, 
            'card-container-active-selection': isActive && isShowSelectionLabel}">
    
    <div class="selection-label-container" *ngIf="isActive && isShowSelectionLabel">
        <img src="assets/check_circle.svg" alt="">
        <span class="selected-label">Selected</span>
    </div>
    
    <ng-content select="[container]"></ng-content>
</div>





