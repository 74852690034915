import { AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';

@Component({
  selector: 'notification-bar',
  templateUrl: './notification-bar.component.html',
  styleUrls: ['./notification-bar.component.scss']
})
export class NotificationBarComponent implements AfterViewInit{
  
  @ViewChild('notification') notification!: ElementRef; 
  @Input() notificationData = [];
  scrollInterval :any;
  
  iteration = 0;

  ngOnInit(): void {
    
  }

  ngAfterViewInit(): void {
    this.notification.nativeElement.innerHTML = '...Loading';

    setTimeout(()=>{
      this.loadData();
    }, 300)
    
  }


  loadData(){
    if(this.notificationData.length == 0){
      return ;
    }

    else{
      this.notification.nativeElement.innerHTML = this.notificationData[this.iteration];
      this.scrollInterval = setInterval(()=>{
          this.notification.nativeElement.innerHTML = this.notificationData[this.iteration];
          this.iteration == this.notificationData.length-1 ? 0 : this.iteration++;
      },5000)
    }
  }

  
}
