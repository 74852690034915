export const noError = {
    "isSuccess" : false,
    "isError" : false,
    "message" : ''

}

export const customerDetails = {
    phone: {
        nullMessage : {
            "isSuccess" : false,
            "isError" : true,
            "message" : 'Phone number is required'
        },
        numericText: {
            "isSuccess" : false,
            "isError" : true,
            "message" : 'Please use numeric text only'
        },
        checkLimit:{
            "isSuccess" : false,
            "isError" : true,
            "message" : 'Please enter a valid phone number'
        },
        callingCodeCheck: {
            "isSuccess" : false,
            "isError" : true,
            "message" : 'Please select country code'
        }
    },

    firstName:{
        nullMessage: {
            "isSuccess" : false,
            "isError" : true,
            "message" : 'First name is required'
        }
    },

    email: {
        nullMessage: {
            "isSuccess" : false,
            "isError" : true,
            "message" : 'email is required'
        },
        validEmail:{
            "isSuccess" : false,
            "isError" : true,
            "message" : 'Please Enter Valid E-mail'
        }
    }
}

export const journeyDetails = {
    pickup: {
        nullMessage : {
            "isSuccess" : false,
            "isError" : true,
            "message" : 'Pickup Location is required'
        }
    },
    drop: {
        nullMessage : {
            "isSuccess" : false,
            "isError" : true,
            "message" : 'Drop Location is required'
        }
    },
    stop: {
        nullMessage : {
            "isSuccess" : false,
            "isError" : true,
            "message" : 'Stop Location is required'
        }
    },
    businessRideReason: {
        nullMessage : {
            "isSuccess" : false,
            "isError" : true,
            "message" : 'Business Ride Reason is required'
        }
    },
    ExpenseCode: {
        nullMessage : {
            "isSuccess" : false,
            "isError" : true,
            "message" : 'Expense Code is required'
        }
    },
    
}