<div class="main-container">
    <div class="service-region-container">
        <div class="region-container">
            <div class="pickup-container">
                <img src="assets/pickup-region.svg" alt="">
                <span>Pickup Available</span>
            </div>

            <div class="drop-container">
                <img src="assets/drop-region.svg" alt="">
                <span>Drop Available</span>
            </div>
        </div>
            
            <div class="ride-type-container">
                <div class="ride-type">
                    <p [ngClass]="{'selected-ride-type': selectedRideType == 'SCHEDULED', 'unselect-ride-type': selectedRideType != 'SCHEDULED'}" (click)="selectRideType('SCHEDULED')">Scheduled</p>
                    <p [ngClass]="{'selected-ride-type': selectedRideType == 'AIRPORT', 'unselect-ride-type': selectedRideType != 'AIRPORT'}" (click)="selectRideType('AIRPORT')">Airport</p>
                    <p [ngClass]="{'selected-ride-type': selectedRideType == 'RENTAL', 'unselect-ride-type': selectedRideType != 'RENTAL'}" (click)="selectRideType('RENTAL')">Rental</p>
                </div>
            </div>            
    </div>

    <div class="service-region-map-container">
        <custom-map mapType="BOUNDS" [regionCoordinates]="selectedBounds" [defaultCoordinates]="zoneBasedDefaultCoordinates"> </custom-map>
    </div>

</div>