<div class="mainContainer">
  <img src="../../../assets/backBtn.svg" class="backBtn" width="3%" *ngIf="showBackBtn" (click)="prevScreen()">
<div class="topSection">
  <img src="../../../assets/blu_logo.svg" alt="" class="logo" />
  <p class="title">{{ title }}</p>

  <div class="credentialContainer">
  <p class="contentContainer" [innerHTML]="content"></p>
  <p *ngIf="showCredential">{{selectedCredentialValue}}</p>
  </div>

  <h3 class="changeMode" (click)="changeVerificationMode()" *ngIf="changeModeFlag">Change Verification Mode</h3>

  <div *ngIf="screenNavigator[0]['isActive']" class="templateContainer">
    <ng-container *ngTemplateOutlet="VERIFICATION_MODE" ></ng-container>
  </div>


  <div *ngIf="screenNavigator[1]['isActive'] || screenNavigator[2]['isActive']" class="templateContainer">
    <ng-container *ngTemplateOutlet="OTP_CONTAINER"></ng-container>
  </div>

  <div *ngIf="screenNavigator[3]['isActive']" class="templateContainer">
    <ng-container *ngTemplateOutlet="INVALID_OTP"></ng-container>
  </div>

</div>


<div class="bottomSection">
  <img src="../../../assets/car-and-city-image.svg" alt="">
</div>  
</div>


<ng-template #VERIFICATION_MODE>
  <div class="card">
    <div class="cardContent">
    <h4>Choose the OTP Verfication mode</h4>
    <div class="methodContainer">
      <p-radioButton name="group1" value="SMS" [label]="verificationMethodLabel[0]" [(ngModel)]="selectedMethod" inputId="opt1" (click)="selectVerificationMethod()"></p-radioButton>
      <p-radioButton name="group1" value="EMAIL" [label]="verificationMethodLabel[1]" [(ngModel)]="selectedMethod" inputId="opt2" (click)="selectVerificationMethod()"></p-radioButton>
    </div>
    <button pButton type="button" label="Proceed" [disabled]="disableProceedCta" [ngClass]="{'enableBtn': !disableProceedBtn, 'disableBtn': disableProceedBtn}" class="proceedCta" (click)="getOtpScreen()"></button>
    </div>
  </div>
</ng-template>


<ng-template #OTP_CONTAINER>
  <div class="card">
    <div class="otpCardContent">
      <h4>Enter your 6 - digit OTP</h4>
      <p *ngIf="!sessionOut">{{timerValue}} Sec Remaining</p>
      <p class="sessionOut" *ngIf="sessionOut">Session Timed Out</p>
      <ngx-otp-input [config]="otpInputConfig" [disable]="timerValue == 0" #ngxotp (fill)="handleFillEvent($event)" (otpChange)="handleChangeEvent($event)"></ngx-otp-input>
    </div>
    <button pButton type="button" label="Verify" [disabled]="disableVerifyCta" [ngClass]="{'enableBtn': !disableProceedBtn, 'disableBtn': disableProceedBtn}" class="verifyCta" (click)="verifyOtp()"></button>
    <div class="resendContainer">
      <span>Didn’t receive OTP?<span class="resendCode" (click) = resendOtp($event)> Resend Code</span></span>
    </div>
  </div>
</ng-template>


<ng-template #INVALID_OTP>
  <div class="card">
    <div class="incorrectOtpContainer">
        <img src="../../../assets/cross.svg" alt="">
        <p>You have entered the incorrect OTP. Account will be locked after {{attemptsLeft}} unsuccessful attempts</p>
        <button pButton type="button" label="Retry" class="verifyCta enableBtn" (click)="prevScreen()"></button>
    </div>
  </div>


</ng-template>