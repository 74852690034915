import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DialogModule } from 'primeng/dialog';
import { SelectRideTypeContainerComponent } from './select-ride-type-container/select-ride-type-container.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ComponentsModule } from '../components/components.module';
import { CreateRideContainerComponent } from './create-ride-v2/create-ride-container.component';
import { CustomerDetailsContainerComponent } from './customer-details-container/customer-details-container.component';
import { CreateRideService } from '../getter-setter/container/create-ride/create-ride-container.service';
import { CommonService } from '../getter-setter/common.service';
import { JourneyDetailsContainerComponent } from './journey-details-container/journey-details-container.component';
import { LocationContainerComponent } from './journey-details-container/location-container/location-container.component';
import { CarVariantContainerComponent } from './journey-details-container/car-variant-container/car-variant-container.component';
import { TimestampContainerComponent } from './journey-details-container/timestamp-container/timestamp-container.component';
import { AddLocationContainerComponent } from './journey-details-container/location-container/add-location-container/add-location-container.component';
import { AdditionalDetailsContainerComponent } from './journey-details-container/location-container/additional-details-container/additional-details-container.component';
import { PaymentDetailsContainerComponent } from './payment-details-container/payment-details-container.component';
import { CurrencyPipe, DateTimePipe } from '../pipe/createridepipe.pipe';
import { HomepageContainerComponent } from './homepage-container/homepage-container.component';
import { PricingContainerComponent } from './homepage-container/pricing-container/pricing-container.component';
import { ServiceableRegionsContainerComponent } from './homepage-container/serviceable-regions-container/serviceable-regions-container.component';
import { FaqContainerComponent } from './homepage-container/faq-container/faq-container.component';
import { UsefulLinksContainerComponent } from './homepage-container/useful-links-container/useful-links-container.component';
import { WhatsNewContainerComponent } from './homepage-container/whats-new-container/whats-new-container.component';
import { PaymentDialogComponent } from 'src/app/main/container/payments/payment-dialog/payment-dialog.component';





@NgModule({
  declarations: [
    SelectRideTypeContainerComponent,
    CreateRideContainerComponent,
    CustomerDetailsContainerComponent,
    JourneyDetailsContainerComponent,
    LocationContainerComponent,
    CarVariantContainerComponent,
    TimestampContainerComponent,
    AddLocationContainerComponent,
    AdditionalDetailsContainerComponent,
    PaymentDetailsContainerComponent,
    DateTimePipe,
    CurrencyPipe,
    HomepageContainerComponent,
    PricingContainerComponent,
    ServiceableRegionsContainerComponent,
    FaqContainerComponent,
    UsefulLinksContainerComponent,
    WhatsNewContainerComponent
  ],

  imports: [
    CommonModule,
    ComponentsModule,
    ReactiveFormsModule,
    FormsModule,
    DialogModule,
    PaymentDialogComponent
  ],
  
  providers: [CreateRideService, CommonService],
  exports: [SelectRideTypeContainerComponent, CreateRideContainerComponent, CustomerDetailsContainerComponent, 
            JourneyDetailsContainerComponent, HomepageContainerComponent]
})
export class ContainerModule { }
