import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';

@Component({
  selector: 'card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.scss']
})
export class CardComponent implements OnChanges {


@Input() type: string;
@Input() isActive: boolean;
@Input() isShowSelectionLabel: boolean;

ngOnChanges(changes: SimpleChanges): void {
}

}
