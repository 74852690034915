import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { API_URL_ADD_MONEY_TO_WALLET_TELLER, API_URL_CHECK_ADD_MONEY_STATUS, API_URL_INITITATE_PAYMENT, API_URL_PERFORM_CREDIT_SUCCESS_ACTION, API_URL_RIDE_ACTION } from 'src/app/config/apiRouteConfig';

@Injectable({
  providedIn: 'root'
})
export class PaymentDetailsApiService {

  constructor(private _http: HttpClient) { }

  initiatePayment(rideRequestId){
    return this._http.get(`${API_URL_INITITATE_PAYMENT}/${rideRequestId}/payment`);
  }

  rideAction(rideRequestId, action){
   return this._http.post(`${API_URL_RIDE_ACTION}/${rideRequestId}/actions`, action);
  }

  getCreditCardStatus(orderId){
    return this._http.get(`${API_URL_PERFORM_CREDIT_SUCCESS_ACTION}${orderId}/status`)
  }

  addMoneyToWalletFromTeller(rideReqId:number){
    return this._http.get(`${API_URL_ADD_MONEY_TO_WALLET_TELLER}/${rideReqId}/payment`)
   }

  checkPrepaidStatus(orderId){
    return this._http.get(API_URL_CHECK_ADD_MONEY_STATUS+'/'+orderId+'/status');
  }
}
