<div class="modal-md" style="z-index: 1200 !important;">
    <div class="modal-header" >
     
        <div style="color: #3E4958; font-size: 24px;font-weight: bold;margin-left: auto !important; ">
        
        </div>
      <button type="button" class="close" aria-label="Close" (click)="close()">
        <span aria-hidden="true" style="color: #3E4958;"><i class="far fa-times-circle"></i></span>
      </button>
    </div>
    <div style="text-align: center;align-items: center;color: #3E4958; font-size: 24px;font-weight: bold;">
      <span style="color: #3E4958; ">Add Money to Wallet</span>
    </div>
    <div class="modal-body">
      <div class="container">
        <div style="display: flex;
        justify-content: center;
        flex-direction: column;
        height: 100%;
        width: 100%;
  ">
          <div style="color: #2C66E3;margin-top: 40px;"> 
            <div style="text-align: center;">Available Balance: {{walletBalance | currencySymbol}}</div>
          </div>
          <span class="p-float-label" style="margin-top: 40px; align-items: center;">
          <input pInputText  type="number" class="form-control custom"
        [(ngModel)]="amountEntered" placeholder="" name="amount"  #amountEnter id="amount"
         required (keyup) = "disableButton()" style="width: 100%; border: none;border-bottom: 1px solid #E5E7EB;" />
         <label for="amount" style="color: #2C66E3;">Enter Amount</label>
        </span>
        <button class="btn btn-primary" [disabled]="disabledButton" (click)="addMoneyToWallet()" style="margin-top: 40px; width: 70%; align-self: center;background-color: #2C66E3;">Proceed</button>
    
    
        </div>
      </div>
        
    </div>
  </div>