import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { API_URL_UPDATE_ACCOUNT_DETAILS } from 'src/app/config/apiRouteConfig';

@Injectable({
  providedIn: 'root'
})
export class BusinessDetailsService {
  private messageSource = new BehaviorSubject([]);
  currentMessage = this.messageSource.asObservable();

  private walletBalanceNoti = new BehaviorSubject([]);
  walletBalanceMessage = this.walletBalanceNoti.asObservable();

  private headerImgUrlNoti = new BehaviorSubject([]);
  headerImgUrlMsg = this.headerImgUrlNoti.asObservable();

  constructor(private _http: HttpClient) { }
  paymentTypeAccepted:any
  rideTypeAllowed:any
  businessName:any;
  pricingModel:any;
  walletBalance:any;
  headerImgUrl:any;
  businessAccountId:any;
  isBusinessRideReasonMandatory:any
  rideReasons:any
  isSubPlaceEnabled:any = false;
  isManagerApprovalEnabled = false;
  setupAccountInfo = {}
  isRecurringRentalsEnabled = false;
  accountSetupComplete = false;

  sharedRideMaxLimit:number =3;
  sharedRideMinLimit:number=2;
  
  getAcceptedPaymentType(){
    // let arr =['CASH','BUSINESS_ACCOUNT'];
    return this.paymentTypeAccepted;
    // return arr;
  }

  setAcceptedPaymentType(paymentTypes:any){
    
    this.paymentTypeAccepted = paymentTypes
    this.messageSource.next(paymentTypes)
  }

  getAllowedRideType(){
    // let arr =["SCHEDULED_RIDE","SCHEDULED_RENTALS"];
    return this.rideTypeAllowed
    // return arr;
  }

  setAllowedRideType(rideTypes:any){
    
    this.rideTypeAllowed = rideTypes;
  }

  setBusinessName(name:any){
    this.businessName = name;
  }

  getBusinessName(){
    return this.businessName;
    // return "UdChalo";
  }

  getPricingModel(){
    return this.pricingModel;
  }

  setPricingModel(pricingModel:any){
    this.pricingModel = pricingModel;
  }

  setWalletBalance(balance:any){
    this.walletBalance = balance;
    this.walletBalanceNoti.next(balance);
  }

  getWalletBalamce(){
    return this.walletBalance;
  }

  setHeaderImgUrl(url:any){
    this.headerImgUrl =url;
    this.headerImgUrlNoti.next(url);
  }

  getHeaderImgUrl(){
    return this.headerImgUrl;
  }

  setBusinessAccountId(businessAccountId:any){
    this.businessAccountId =  businessAccountId;
  }

  getBusinessAccountId(){
    return this.businessAccountId;
  }
  setIsBusinessRideReasonMandatory(isBusinessRideReasonMandatory:any){
    this.isBusinessRideReasonMandatory = isBusinessRideReasonMandatory;
  }
  getIsBusinessRideReasonMandatory(){
    return this.isBusinessRideReasonMandatory;
  }
  setRideReasons(rideReason:any){
    this.rideReasons = rideReason
  }
  getRideReason(){
    return this.rideReasons;
  }
  getIsSubPlaceEnabledFlag(){
    return this.isSubPlaceEnabled;
  }


  setIsSubPlaceEnabledFlag(isSubPlaceEnabled: any){
    this.isSubPlaceEnabled = isSubPlaceEnabled;
  }

  getManagerApprovalEnabledFlag(){
    return this.isManagerApprovalEnabled;
  }

  setIsManagerApprovalEnabledFlag(isManagerApprovalEnabled:any){
    this.isManagerApprovalEnabled = isManagerApprovalEnabled;
  }

  setIsRecurringRentalEnabledFlag(isRecurringRentalsEnabled:any){
    this.isRecurringRentalsEnabled = isRecurringRentalsEnabled == null ? false : isRecurringRentalsEnabled;
  }

  getIsRecurringRentalsEnabledFlag(){
    return this.isRecurringRentalsEnabled;
  }
  setAccountInfo(accountInfo:any){
    this.setupAccountInfo = accountInfo;
  }

  getSetupAccountInfo(){
    return this.setupAccountInfo;
  }

  updateAccountSetupDetails(businessAccountId, body){
    return this._http.put(`${API_URL_UPDATE_ACCOUNT_DETAILS}/${businessAccountId}/update`, body)
  }

  setSharedRideMaxLimit(limit){
    this.sharedRideMaxLimit = limit;
  }
  getSharedRideMaxLimit(){
    return this.sharedRideMaxLimit;
  }

  setSharedRideMinLimit(limit){
    this.sharedRideMinLimit = limit;
  }

  getSharedRideMinLimit(){
    return this.sharedRideMinLimit;
  }

  getBusinessAccCode(){
    return localStorage.getItem('businessAccountCode');
  }

  getBaseLocationDetails(){
   let baseLocationDto = {
    baseLocation : this.setupAccountInfo['baseLocation'],
    businessSpecs:this.setupAccountInfo['businessSpecs']?this.setupAccountInfo['businessSpecs'] : null
   }
   return baseLocationDto;
  }


}
