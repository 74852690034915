import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginGuard } from './guards/login-route.guard';
import { LoginComponent } from './main/login/login.component';
import { ResetPasswordComponent } from './main/reset-password/reset-password.component';
import { CanActivateRouteGuard } from './guards/can-activate-route.guard';
import { TncComponent } from './main/container/rides/tnc/tnc.component';
import { MultiFactorComponent } from './main/multi-factor/multi-factor.component';
import { CreateRidePageComponent } from './app-v2/pages/create-ride-page/create-ride-page.component';
import { SelectRideTypePageComponent } from './app-v2/pages/select-ride-type-page/select-ride-type-page.component';
import { CreateSharedRidePageComponent } from './app-v2/pages/create-shared-ride-page/create-shared-ride-page.component';
import { HomePageComponent } from './app-v2/pages/home-page/home-page.component';

import { CallbackComponent } from './main/common/sso/callback/callback.component';

const routes: Routes = [
  {
    path:'resetpwd',
    component:ResetPasswordComponent,
    canActivate:[LoginGuard]

  },
  { path: 'callback', component: CallbackComponent,canActivate:[LoginGuard] },
  {
    path:'mfa',
    component:MultiFactorComponent,
    canActivate:[LoginGuard]

  },
  {
    path:'home',
    component: HomePageComponent,
    canActivate:[CanActivateRouteGuard],
  },
  {
    path:'rides',
    loadChildren: () => import('./main/container/rides/rides.module').then(m => m.RidesModule),
    canActivate:[CanActivateRouteGuard]
  },
  {
    path:'login',
    component:LoginComponent,
    canActivate:[LoginGuard]
  },
  {
    path:'select',
    component:SelectRideTypePageComponent,
    canActivate:[CanActivateRouteGuard]
  },

  {
    path:'create',
    component:CreateRidePageComponent,
    canActivate:[CanActivateRouteGuard]
  },

  {
    path:'sharedride',
    component:CreateSharedRidePageComponent,
    canActivate:[CanActivateRouteGuard]
  },
  
  {
    path:'setup',
    loadChildren: ()=> import('./main/container/setup-account/setup-account.module').then(m=>m.SetupAccountModule),
    canActivate:[CanActivateRouteGuard]
  },
  {
    path:'users',
    loadChildren: ()=> import('./main/container/users/users.module').then(m=>m.UsersModule),
    canActivate:[CanActivateRouteGuard]

  },
  {
    path:'payments',
    loadChildren: ()=>import('./main/container/payments/payments.module').then(m=>m.PaymentsModule),
    canActivate:[CanActivateRouteGuard]

  },
  {
    path:'reports',
    loadChildren: ()=>import ('./main/container/reports/reports.module').then(m=> m.ReportsModule),
    canActivate: [CanActivateRouteGuard]
  },
  {
    path:'map',
    loadChildren: ()=>import('./main/container/live-map/live-map.module').then(m=>m.LiveMapModule),
    canActivate:[CanActivateRouteGuard]

  },
  
  {
    path:'help',
    loadChildren: ()=>import('./main/container/help/help.module').then(m=>m.HelpModule),
    canActivate:[CanActivateRouteGuard]

  },
  {
    path:'setting',
    loadChildren:()=>import('./main/container/setting/setting.module').then(m=>m.SettingModule),
    canActivate:[CanActivateRouteGuard]

  },
  {
    path:'admin-setting',
    loadChildren:()=>import('./main/container/admin-settings/admin-settings.module').then(m=>m.AdminSettingsModule),
    canActivate:[CanActivateRouteGuard]

  },
  {
    path:'info',
    component:TncComponent,
    canActivate:[CanActivateRouteGuard]

  },
  {
    path:'',
    component:LoginComponent,
    canActivate:[LoginGuard]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
