import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { CommonService } from 'src/app/app-v2/getter-setter/common.service';
import { SlideCarouselData } from 'src/app/app-v2/types/components.type';
import { RideType } from 'src/app/app-v2/types/container.type';

@Component({
  selector: 'pricing-container',
  templateUrl: './pricing-container.component.html',
  styleUrls: ['./pricing-container.component.scss']
})


export class PricingContainerComponent implements OnInit, OnChanges {

allowedRideTypes:RideType[];
selectedRideType: string = 'SCHEDULED_RIDE';
@Input() pricingData = {};
selectedPricingData = [];
selectedDataFields = [];
selectedFieldNotes = [];
notesTitle = ''
@Input() selectedZone = '0';
maxRows = 5;
startIndex = 0;
endIndex = this.maxRows;

pricingDataLength = 0;
allSelectedFields = [];
 
constructor(private _common: CommonService){}

ngOnChanges(changes: SimpleChanges): void {
  this.setPricingData();
}

ngOnInit(): void {
 this.allowedRideTypes = this._common.getAllowedRideTypesDetails();
 this.setPricingData();
}


selectRideType(index:number){
this.selectedRideType = this.allowedRideTypes[index].value;
this.startIndex = 0;
this.endIndex = this.maxRows;
this.setPricingData();
} 

setPricingData(){
  let selectedZone = this.selectedZone['value'];
  let rideType = this.selectedRideType == 'SHARED_RIDE' ? 'SCHEDULED_RIDE' : this.selectedRideType;

  this.pricingDataLength = this.pricingData?.[selectedZone]?.[rideType]?.['data'].length;
  this.endIndex = this.pricingDataLength < this.endIndex ? this.pricingDataLength : this.endIndex;

  this.selectedDataFields = this.pricingData?.[selectedZone]?.[rideType]?.['fields'];
  this.notesTitle = this.pricingData?.[selectedZone]?.[rideType]?.['notes']? this.pricingData[selectedZone][rideType]['notes']['title'] : null
  this.selectedFieldNotes = this.pricingData?.[selectedZone]?.[rideType]?.['notes'] ? this.pricingData[selectedZone][rideType]['notes']['text'] : [];

  this.allSelectedFields = this.selectedDataFields ? this.selectedDataFields.map(field=>field.label) : []
  this.selectedPricingData = [];
  this.selectedPricingData = this.getPricingData(this.pricingData?.[selectedZone]?.[rideType]?.['data'], this.startIndex, this.endIndex);
}


getPricingData(data, startIndex, endIndex){
  if(data){
    let tempArr = [];
    for(let i = startIndex; i < endIndex; i++){
      tempArr.push(data[i]);
    }
    return tempArr;
  }
}


slide(dir){
if(dir == 'forward'){
  if(this.endIndex + this.maxRows >= this.pricingDataLength){
    this.startIndex = this.endIndex == this.pricingDataLength ? this.startIndex : this.endIndex;
    this.endIndex = this.pricingDataLength;
    this.setPricingData();
  }

  else{
    this.startIndex = this.endIndex;
    this.endIndex += this.maxRows;
    this.setPricingData()
  }
}

else{
  if(this.startIndex - this.maxRows <= 0){
    this.endIndex = this.startIndex == 0 ? this.endIndex : this.startIndex;
    this.startIndex = 0;
    this.setPricingData();
  }
  else{
    this.endIndex = this.startIndex;
    this.startIndex -= this.maxRows;
    this.setPricingData();
  }
}
}


getColumnWidth(field) {
  let index = this.allSelectedFields.findIndex(fields=>fields == field);
  return this.selectedDataFields[index == -1 ? 0 : index]['width'];
}


}
