import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';

@Component({
  selector: 'faq-container',
  templateUrl: './faq-container.component.html',
  styleUrls: ['./faq-container.component.scss']
})
export class FaqContainerComponent implements  OnInit ,OnChanges{


@Input() faqList = [];
faqAccList = [];



ngOnChanges(changes: SimpleChanges): void {
  this.fetchFaqs();
}



ngOnInit(): void {
  this.fetchFaqs();
}



fetchFaqs(){
  this.faqAccList = this.faqList.map((faq, index)=>{
    let newLabel = `Q${index+1}. ${faq.label}`
    return {label :  newLabel , isActive: false, isComplete : false,  isVisible:true, isDisabled: false} 
  })
}

}