<div class="container_expand" *ngIf='appService.isLoad'>
  <app-header (margin)="marginChangeHandler($event)" *ngIf='appService.isValid'></app-header>
  <div class="right-content" [ngClass]="{marginLess: moreMargin,marginMore: !moreMargin, zeroMargin: !appService.isValid}" >
    <div class="delay-wrapper" *ngIf="showDelayAlert && !hideAlert">
      <div class="delay-content">
        {{delayMsg}}
      </div>
    </div>
    <router-outlet></router-outlet>
  </div>
</div>
<ngx-spinner type="ball-scale-multiple"></ngx-spinner>