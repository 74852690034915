<div class="main-container" *ngIf="type == 'FADE'">
    <div class="carousel-container" [@fadeIn]="animationStatus == 'IN'? '*' : 'void'"
                       [@fadeOut]="animationStatus == 'OUT'? 'void' : '*'">
        <img [src]="activeImgUrl">
    </div>
</div>



<div class="slide-main-container" *ngIf="type == 'SLIDE'">
    <div class="slide-control-container" [ngStyle]="{'transform' : 'translateX(' + slidePercentage + '%)', 'width': slideCarouselData.length <= 3 ? '100%' : '133%' }">
    <div class="slide-carousel-container-data" *ngFor="let item of slideCarouselData">
        <card type="main">
            <div class="" [ngClass]="{'slide-carousel-container-horizontal': align == 'HORIZONTAL', 'slide-carousel-container-vertical': align == 'VERTICAL'}" main>
                <img [src]="item.url" alt="">

                <div class="carousel-text-container">
                    <span class="carousel-title">{{item.title}}</span>
                    <span class="carousel-text">{{item.text}}</span>
                </div>
            </div>
        </card>
    </div>
    </div>
</div>


<div [ngClass]="{'controls-slide' : type == 'SLIDE', 'controls-fade' : type == 'FADE'}" *ngIf="slideCarouselData && slideCarouselData.length > 3">
    <img src="assets/backward.svg" alt="" (click)="slide('right')">
    <img src="assets/forward.svg" alt="" (click)="slide('left')">
</div>