import { Component } from '@angular/core';

@Component({
  selector: 'app-help',
  templateUrl: './help.component.html',
  styleUrls: ['./help.component.css']
})
export class HelpComponent {


  download(){
    window.location.href = 'https://dbvqr1onrw1s8.cloudfront.net/symphony/help/Blu_for_Business_helpDeck.pdf';
  }
}
