import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AccordianDetails } from '../../types/components.type';

@Component({
  selector: 'accordian',
  templateUrl: './accordian.component.html',
  styleUrls: ['./accordian.component.scss']
})
export class AccordianComponent {

  @Input() accordianDetails: AccordianDetails
  @Output() isActiveEvent= new EventEmitter<boolean>()
  

  toggleAccordian(){
    if(!this.accordianDetails.isDisabled){
      this.accordianDetails.isActive = !this.accordianDetails.isActive;
      this.isActiveEvent.emit(this.accordianDetails.isActive);
    }
    else{
      return "";
    }
    
  }
}
