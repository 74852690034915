import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { CreateRideSteps, CustomerDetails, CustomerDetailsApiResponse, JourneyDetailsStep, RideType } from '../../types/container.type';
import { CommonService } from '../../getter-setter/common.service';
import { CustomerDetailsApiService } from '../../api-services/create-ride/customer-details-api.service';
import { CustomerDetailsContainerService } from '../../getter-setter/container/create-ride/customer-details-container.service';
import { CustomButton, InfoType, Theme } from '../../types/components.type';
import { CreateRideService } from '../../getter-setter/container/create-ride/create-ride-container.service';
import { SelectRideTypeContainerService } from '../../getter-setter/container/create-ride/select-ride-type-container.service';
import { CreateRideSubscriptionService } from '../../subscriptions/create-ride/create-ride-subscription.service';
import { CommonSubscriptionService } from '../../subscriptions/common/common-subscription.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { journeyDetails } from '../../validations/createRide/create-ride-validation';


@Component({
  selector: 'customer-details-container',
  templateUrl: './customer-details-container.component.html',
  styleUrls: ['./customer-details-container.component.scss']
})
export class CustomerDetailsContainerComponent implements OnInit {
  nextCta: CustomButton = {
    label: 'Next',
    left: false,
    right: false,
    leftUrl: "",
    rightUrl: "",
    disabled: false
  }

  selectedRideType: RideType;
  customerDetailsForService: Omit<CustomerDetailsApiResponse, 'ssoId' | 'riderId'> & { isFilled: boolean }
  
  customerDetails: CustomerDetails = {
    phoneNumber: {
      callingCodesList: this._common.getCallingCodeList(),
      callingCodeValue: this._customerService.getDefaultCallingCode(),
      inputValue: '',
      limit: this._customerService.getDefaultCallingCode()['limit'],
      validation: {
        isError: false,
        isSuccess: false,
        message: ''
      },
      label: 'Phone Number',
      name: 'phone',
      placeholder: `${this._customerService.getDefaultCallingCode()['limit']}-digit-number`,
      type: 'text',
      isDisabled: false,
      required: true,
      enableTooltip: false,
      tooltipContent: ''
    },

    firstName: {
      placeholder: '',
      type: 'text',
      inputValue: null,
      label: "First Name",
      name: 'first-name',
      validation: {
        isError: false,
        isSuccess: false,
        message: ''
      },
      isDisabled: true,
      required: true,
      enableTooltip: false,
      tooltipContent: ''
    },

    lastName: {
      placeholder: '',
      type: 'text',
      inputValue: null,
      label: "Last Name",
      name: 'last-name',
      validation: null,
      isDisabled: true,
      required: false,
      enableTooltip: false,
      tooltipContent: ''
    },

    email: {
      placeholder: '',
      type: 'email',
      inputValue: null,
      label: "E-mail",
      name: 'email',
      validation: {
        isError: false,
        isSuccess: false,
        message: ''
      },
      isDisabled: true,
      required: true,
      enableTooltip: false,
      tooltipContent: ''
    }
  };

  constructor(private _customerService: CustomerDetailsContainerService, 
              private _common: CommonService, 
              private _customerServiceApi: CustomerDetailsApiService, 
              private _createRide: CreateRideService, 
              private _rideType: SelectRideTypeContainerService,
              private _subscription: CreateRideSubscriptionService,
              private _commonSubscription: CommonSubscriptionService,
              private _loader: NgxSpinnerService) {

  }
  ngOnInit(): void {
    this.selectedRideType = this._rideType.getSelectedRideTypeDetails();
    this.setPreviousCustomerDetails(this._customerService.getCustomerDetails()); 
  }


  setPreviousCustomerDetails(previousDetails){
    
    if(previousDetails != null){

      Object.keys(previousDetails).forEach(inpField=>{
        
        if(inpField == 'phoneNumber'){
                this.customerDetails.phoneNumber.callingCodeValue.value = previousDetails[inpField][0];
                this.customerDetails[inpField]['inputValue'] = previousDetails[inpField][1];
                return ;
              }
        else if(inpField != 'isFilled'){
                this.customerDetails[inpField]['inputValue'] = previousDetails[inpField];
                return ;
              }                          
      })
    }
    else{
      return '';
    }
      
  }

  // get user information using phone
 async getInfoFromInputThroughApi(data) {
    
    this.customerDetails.phoneNumber.limit = this.customerDetails.phoneNumber.callingCodeValue.limit;
    this.customerDetails.phoneNumber.placeholder = `${this.customerDetails.phoneNumber.limit}-digit-number`;

    if (data == 'PHONE') {
      this.clearCustomerDetailsStepProcess();
      if (this.customerDetails.phoneNumber.inputValue != null && this.customerDetails.phoneNumber.callingCodeValue.limit === this.customerDetails.phoneNumber.inputValue.length) {
        
        let res = await this._customerService.checkValidation('phoneNumber', this.customerDetails['phoneNumber']['callingCodeValue']);
        this.customerDetails.phoneNumber.validation = res;

        if(!this.customerDetails.phoneNumber.validation.isError){
          let contactDetails = {
            callingCodeValue: this.customerDetails.phoneNumber.callingCodeValue.value,
            inputValue: this.customerDetails.phoneNumber.inputValue
          }
  
          this._loader.show();
          this._customerServiceApi.fetchCustomerDetails(contactDetails).subscribe(res => {
            this._loader.hide();
            if (res['statusCode'] == 200) {
              if (res['response'] == null) {
                this.customerDetails.phoneNumber.validation = {
                  isSuccess: true,
                  isError: false,
                  message: 'Number Added !!'
                }
                Object.keys(this.customerDetails).forEach(step => this.customerDetails[step]['isDisabled'] = false);
              }
              else {
                Object.keys(this.customerDetails).forEach(step => this.customerDetails[step]['inputValue'] = res['response'][step]);
              }
            }
  
            else{
              this._commonSubscription.emitAlert({ theme : Theme.ERROR, title: 'Technical Error', message: res['message'], ctaLabel: 'Ok !'});
            }
          })
        }
        else{
          return ;
        }  
        }
    }
  }


  async submitCustomerDetails() {
    this.customerDetailsForService = {
      callingCode: this.customerDetails.phoneNumber.callingCodeValue.value,
      phoneNumber: this.customerDetails.phoneNumber.inputValue,
      firstName: this.customerDetails.firstName.inputValue,
      lastName: this.customerDetails.lastName.inputValue,
      email: this.customerDetails.email.inputValue,
      isFilled: false
    }
    this._customerService.setCustomerDetails(this.customerDetailsForService);

    // validation
    const promises = Object.keys(this.customerDetails).map(async inpField => {
      if (this.customerDetails[inpField]['validation'] != null) {
          if(inpField == 'phoneNumber'){
            const res = await this._customerService.checkValidation(inpField, this.customerDetails[inpField]['callingCodeValue']);
            this.customerDetails[inpField]['validation'] = res;
          }
          else{
            const res = await this._customerService.checkValidation(inpField);
            this.customerDetails[inpField]['validation'] = res;  
          } 
        }
      else {
        this.customerDetails[inpField]['validation'] = null;
      }
    });
   
    await Promise.all(promises);
   
   let validatorStatus = Object.keys(this.customerDetails).map(inpField => {
      return this.customerDetails[inpField]['validation'] != null ? this.customerDetails[inpField]['validation']['isError'] : false;
    });

    if (!validatorStatus.includes(true)) {
      this.customerDetailsForService.isFilled = true;
      this._createRide.setSelectedJourneyDetailsStep(JourneyDetailsStep.LOCATION_DETAILS);
      this._subscription.emitCreateRideActiveStepObserver(CreateRideSteps.JOURNEY_DETAILS);
    }
    else {
      return;
    }

  }



  clearCustomerDetailsStepProcess() {
    Object.keys(this.customerDetails).forEach(field=>{
      this.customerDetails[field].inputValue = field == 'phoneNumber' ? this.customerDetails[field].inputValue : null;
      this.customerDetails[field].isDisabled = field == 'phoneNumber' ? false : true;
      this.customerDetails[field].validation = {
        isError: false,
        isSuccess: false,
        message: ''
      }
    })
    
    this._createRide.alterCreateRideStepData(CreateRideSteps.CUSTOMER_DETAILS, 'isComplete', false);
    this._createRide.alterCreateRideStepData(CreateRideSteps.JOURNEY_DETAILS, 'isActive', false);

    this.customerDetailsForService = {
      callingCode: '',
      phoneNumber: this.customerDetails.phoneNumber.inputValue,
      firstName: this.customerDetails.firstName.inputValue,
      lastName: this.customerDetails.lastName.inputValue,
      email: this.customerDetails.lastName.inputValue,
      isFilled: false
    }

    this._customerService.setCustomerDetails(this.customerDetailsForService);

  };

}
