import { AfterViewInit, Component, ElementRef, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild, ViewChildren } from '@angular/core';
import { fadeInCarousel, fadeOutCarousel, slideLeft } from '../../animations/components/carousel-animation';
import { SlideCarouselData } from '../../types/components.type';


let duration = 500;
@Component({
  selector: 'carousel',
  templateUrl: './carousel.component.html',
  styleUrls: ['./carousel.component.scss'],
  animations:[fadeInCarousel(duration), fadeOutCarousel(duration), slideLeft()]
})
export class CarouselComponent implements OnInit ,OnChanges, OnDestroy{
  
  @Input() carouselImages : string[];
  @Input() slideCarouselData : SlideCarouselData[];
  @Input() align: string;
  @Input() type: string;
  @Input() speed:number = 0;

  activeImgUrl:string
  activeIndex:number;
  scrollInterval;


  animationStatus:string;
  slidePercentage:number;
  shiftpercentage: number = 25;

  ngOnInit(): void {
  this.animationStatus = 'IN';
  this.slidePercentage = 0;
  this.activeIndex = 0;
  

  if(this.type == 'FADE'){
    this.activeImgUrl = this.carouselImages[this.activeIndex]; 
  }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(this.type == 'FADE'){

      if(this.carouselImages.length > 1){
        this.scrollInterval = setInterval(()=>{
          this.fadeOut().then(res=>{
            this.activeImgUrl = this.carouselImages[this.activeIndex];
            this.fadeIn().then((res)=>{
              this.activeIndex  == this.carouselImages.length - 1 ?  this.activeIndex = 0  : this.activeIndex++;    
            })
          });
         },this.speed);
      }

      else{
        this.activeImgUrl = this.carouselImages[this.activeIndex];
      }
     
    }
   
  }


  fadeOut(){
    let x = new Promise((resolve, reject)=>{
      this.animationStatus = 'OUT';
      setTimeout(()=>{
        resolve(true);
      },duration);
    })
    return x;
  }


  fadeIn(){
    let x = new Promise((resolve, reject)=>{
      this.animationStatus = 'IN';
      setTimeout(()=>{
        resolve(true);
      },duration);
    })
    return x;
  }

  slide(dir){
    if(this.slideCarouselData.length <= 3){
      return;
    }
    else{
      let perc = this.slidePercentage;
      perc = perc + (dir == 'left' ? -this.shiftpercentage : +this.shiftpercentage);
      this.slidePercentage = perc > 0 || Math.abs(perc) > this.shiftpercentage ? this.slidePercentage : perc;
    }
  }

  ngOnDestroy(): void {
   clearInterval(this.scrollInterval);
  }

}
