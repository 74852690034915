import { AppService } from './services/app.service';
import Swal from 'sweetalert2';
import { Injectable, Inject, LOCALE_ID } from "@angular/core";
import { tap } from "rxjs/operators";
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpResponse,
  HttpErrorResponse,
  HttpHeaders
} from "@angular/common/http";
import { environment } from "../environments/environment";
import { Observable } from "rxjs";
import { CookieService } from 'ngx-cookie-service';
import { Router } from '@angular/router';
import { COOKIE_URL,COOKIE_SUFFIX } from './config/apiRouteConfig';
@Injectable()
export class AppInterceptor implements HttpInterceptor {
  constructor(private _cookieService: CookieService, private _appService: AppService, private _router: Router,@Inject(LOCALE_ID) public locale: string) {
  }
  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    // const token = this._cookieService.get(`accessToken${COOKIE_SUFFIX}`);
    const token = localStorage.getItem('accessToken');
    // const hub = this._cookieService.get('selectedHub');
    // const project = this._cookieService.get('selectedProject');
    let updatedRequest;
    let countryId = this.locale.split('-')[1];

    const header: any = {};
    for (const headerKey of request.headers.keys()) {
      header[headerKey] = request.headers.get(headerKey);
    }
    // if (hub) {
    //   header.hub = hub;
    // }
    // if (project) {
    //   header.project = project;
    // }

    header['Strict-Transport-Security'] = 'max-age=63072000; includeSubDomains; preload';
    header['Content-Security-Policy'] = "default-src 'self'";
    header['X-Frame-Options'] ='deny';
    header['X-Content-Type-Options'] = 'nosniff';
    header['X-XSS-Protection'] = '1; mode=block';
    header['Referrer-Policy'] = 'origin-when-cross-origin';
    header['Permissions-Policy'] = 'geolocation=(self)';
    header['Cross-Origin-Resource-Policy'] = 'same-origin';
    header['Cross-Origin-Embedder-Policy'] = 'require-corp';
    header['Cross-Origin-Opener-Policy'] = 'same-origin';
    header['Access-Control-Allow-Origin'] = environment.baseUrl;
    header['Expect-CT'] = 'enforce, max-age=86400';
    header['country'] = countryId;
    header['callingCode']= countryId == 'IN' ? "+91" : "+971";

    if (token) {
      // this._appService.getCountry()== 'AE' ? this._appService.setCurrentServiceRegionId([3]) : this._appService.setCurrentServiceRegionId([1,2])
      header.Authorization = `Bearer ${token}`;
      // header.serviceRegionId = this._appService.getCurrentServiceRegionId();
      header.businessAccountId = localStorage.getItem('businessAccountId');
      header.zoneIds = localStorage.getItem('zoneIds')

      updatedRequest = request.clone({
        headers: new HttpHeaders(header)
      });

    } else {
      const header: any = {};
      for (const headerKey of request.headers.keys()) {
        header[headerKey] = request.headers.get(headerKey);
      }
      header['country'] = countryId;
      header['callingCode']= countryId == 'IN' ? "+91" : "+971";
      
      updatedRequest = request.clone({
        headers: new HttpHeaders(header)
      });
    }

    return next.handle(updatedRequest).pipe(
      tap(
        event => {
          // logging the http response to browser's console in case of a success
          if (event instanceof HttpResponse) {
            if(localStorage.getItem('ishmacenabled') == null && event.headers.get("ishmacenabled") == null)
              localStorage.setItem("ishmacenabled","false");
            if(event.headers.get("ishmacenabled") != null)
              localStorage.setItem("ishmacenabled",event.headers.get("ishmacenabled")+"");
            //console.log('api call success :', event.body.statusCode);
            if (event.body.statusCode == 401) {

              // console.log("App Interceptor")
              // Swal.fire({
              //   icon: 'info',
              //   title: 'Hmm..',
              //   text: event.body.message,
              // })
              this._cookieService.delete('accessToken'+COOKIE_SUFFIX, '/',COOKIE_URL);
              localStorage.removeItem('accessToken');
              this._router.navigateByUrl('/login');
              this._appService.isValid = false;
            }
            else {
            }
          }
        },
        error => {
          // logging the http response to browser's console in case of a failuer
          if (error instanceof HttpErrorResponse) {
            // console.log('api call error :',error);
            // Swal.fire({
            //   icon: 'error',
            //   title: 'Oops...',
            //   text: 'Something went wrong!',
            // })
          }
        }
      )
    );
  }
}
