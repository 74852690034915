import { AfterViewInit, Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subscription } from 'rxjs';
import { JourneyDetailsApiService } from 'src/app/app-v2/api-services/create-ride/journey-details-api.service';
import { CommonService } from 'src/app/app-v2/getter-setter/common.service';
import { CreateRideService } from 'src/app/app-v2/getter-setter/container/create-ride/create-ride-container.service';
import { JourneyDetailsContainerService } from 'src/app/app-v2/getter-setter/container/create-ride/journey-details-container.service';
import { SelectRideTypeContainerService } from 'src/app/app-v2/getter-setter/container/create-ride/select-ride-type-container.service';
import { CommonSubscriptionService } from 'src/app/app-v2/subscriptions/common/common-subscription.service';
import { CreateRideSubscriptionService } from 'src/app/app-v2/subscriptions/create-ride/create-ride-subscription.service';
import { CalendarType, CustomButton, CustomDropDownType, CustomInputType, Theme } from 'src/app/app-v2/types/components.type';
import { CreateRideSteps, JourneyDetailsStep } from 'src/app/app-v2/types/container.type';

@Component({
  selector: 'timestamp-container',
  templateUrl: './timestamp-container.component.html',
  styleUrls: ['./timestamp-container.component.scss']
})
export class TimestampContainerComponent implements OnInit{

  allSlotsData = {};
  zoneBasedLeadTime = 0;

  timeStampContainerCta: CustomButton = {
    label: 'Next',
    left: false,
    right: false,
    leftUrl: "",
    rightUrl: "",
    disabled: false
  }

  timestampInputDetails: CalendarType = {
    placeholder : this._rideType.getSelectedRideTypeDetails().slotsRequired ? '(DD/MM/YYYY)' : '(DD/MM/YYYY) (HH/MM)',
    inputValue: null,
    label: !this._rideType.getSelectedRideTypeDetails().slotsRequired ? "Select Date and Time" : "DD/MM/YYYY",
    name: 'dateTime',
    validation:  null,
    isDisabled: false,
    format: 'dd/mm/yy',
    selection: 'single',
    showTime : !this._rideType.getSelectedRideTypeDetails().slotsRequired,
    minDate: new Date(),
    stepMinute: this._common.getIsAdminUser() ? 1 : 15,
    stepHour: 1,
    maxDate: this.setMaxDate(),
    required: true,
    enableTooltip: false,
    tooltipContent: ''
  }

  hourDropDownDetails: CustomDropDownType ={
    selectedValue: {label: '', value: ''},
    validation:  null,
    options: [],
    label: 'HH',
    name: 'hourSlots',
    placeholder : 'Select Hour',
    isDisabled: true,
    required: true,
    enableTooltip: false,
    tooltipContent: ''
  }


  minDropDownDetails: CustomDropDownType ={
    selectedValue: {label: '', value: ''},
    validation:  null,
    options: [],
    label: 'MM',
    name: 'minSlots',
    placeholder : 'Select Minutes',
    isDisabled: true,
    required: true,
    enableTooltip: false,
    tooltipContent: ''
  }

  slots;
  isSlotsRequired = this._rideType.getSelectedRideTypeDetails().slotsRequired;

  constructor(private _journey: JourneyDetailsContainerService,
              private _createRide: CreateRideService,
              private _journeyApi: JourneyDetailsApiService,
              private _subscription: CreateRideSubscriptionService,
              private _loader: NgxSpinnerService,
              private _rideType: SelectRideTypeContainerService,
              private _common: CommonService,
              private _commonSubscription: CommonSubscriptionService,
              
  ){}

  ngOnInit(): void {
    this._journey.setSelectedZoneIdForLeadTime();
    this._common.setLeadTimes();
    this.isSlotsRequired = this._rideType.getIsSlotRequired();  
    this.fetchPrevData();
  }

  checkIfSlotsRequired(){
    let isPremiumRide = this._journey.getSelectedCarVariantCategory() == 'PREMIUM';
    this.isSlotsRequired = isPremiumRide ? true : false;
    this._rideType.setIsSlotRequired(this.isSlotsRequired);

    this.timestampInputDetails.placeholder = this.isSlotsRequired ? '(DD/MM/YYYY)' : '(DD/MM/YYYY) (HH/MM)';
    this.timestampInputDetails.label = !this.isSlotsRequired ? "Select Date and Time" : "DD/MM/YYYY";
    this.timestampInputDetails.showTime = !this.isSlotsRequired;
  }

  
  panelVisible(){
    this.timestampInputDetails.inputValue = this.timestampInputDetails.inputValue == null ? this.handleLeadTime() : this.timestampInputDetails.inputValue; 
    this._journey.setTimestampDetails(this.timestampInputDetails.inputValue.getTime());
    if(this.isSlotsRequired){
      return ;
    }
    else{
      this.timestampInputDetails.minDate = this.handleLeadTime();
    }
  }

  timeStampCtaClick(){
    if(this.isSlotsRequired){
     
      let selectedValue = this.timestampInputDetails.inputValue ? new Date(this.timestampInputDetails.inputValue) : null;
      
      let selectedDay = new Date(selectedValue).getDate();
      let selectedMonth = new Date(selectedValue).getMonth() + 1;
      let selectedYear = new Date(selectedValue).getFullYear();

      let selectedHours = this.hourDropDownDetails.selectedValue ? this.hourDropDownDetails.selectedValue.value : null;
      let selectedMinutes = this.minDropDownDetails.selectedValue ? this.minDropDownDetails.selectedValue.value : null;

      let selectedDate = new Date(`${selectedMonth}/${selectedDay}/${selectedYear},${selectedHours}:${selectedMinutes}`);

      this._journey.setTimestampDetails(selectedDate.getTime());

      if(selectedValue == null || selectedHours == '' || selectedMinutes == '' || selectedHours == null || selectedMinutes == null){
        this._commonSubscription.emitAlert({
          theme: Theme.WARNING,
          title: 'Select Date !',
          message: 'Please Select Date and slots to continue',
          ctaLabel: 'OK'
        });
        return ;
      }
      else{
        this._subscription.emitTriggerTimestampCtaObserver(true);
        return;
      }
      
    }

    else{
      if(this.timestampInputDetails.inputValue == null){
        this._commonSubscription.emitAlert({
          theme: Theme.WARNING,
          title: 'Select Date !',
          message: 'Please Select Date and time to continue',
          ctaLabel: 'OK'
        });
        return;
      }

      else{
        this._subscription.emitTriggerTimestampCtaObserver(true);
        return;
      }
    }
  }

  clearTimeStampDetails(){
    if(this.isSlotsRequired){
      this.hourDropDownDetails.selectedValue = {label: '', value: ''};
      this.minDropDownDetails.selectedValue = {label: '', value: ''};
      return;
    }
    else{
      return ;
    }
  }

  setTimestamp(date){
    if(this.isSlotsRequired){
      this.hourDropDownDetails.selectedValue = {label: '', value: ''};
      this.minDropDownDetails.selectedValue = {label: '', value: ''};
      this._journey.setTimestampDetails(null);
      this.fetchSlots(new Date(this.timestampInputDetails.inputValue).getTime());
    }
    else{
      this._journey.setTimestampDetails(new Date(this.timestampInputDetails.inputValue).getTime());
    }
  }


  fetchSlots(selectedDate){
  let x  = new Promise((resolve, reject)=>{

  let slotsDto = {
    ssoId : localStorage.getItem('ssoId'),
    priceMapId : this._journey.getPriceMapId() ? this._journey.getPriceMapId() : 0,
    zoneId : this._journey.getPickupLocationZoneId(),
    isScheduled : !this._createRide.isRentalRide(),
    isPremium : this._journey.getSubCategory() ? (this._journey.getSubCategory().split("_")[0] == 'PREMIUM' ? true : false) : true,
    dateTime: new Date(this.timestampInputDetails.inputValue).getTime()
  }
  
  this._loader.show();
  this._journeyApi.fetchSlots(slotsDto).subscribe(async res=>{
    this._loader.hide();
    if(res['statusCode'] == 200){
        this.allSlotsData = await res['response']['dateSlots'].length > 0 ? res['response']['dateSlots'][0]['timeGroupSlotsResponseList'] : {};
        if(Object.keys(this.allSlotsData).length < 1){
          this._commonSubscription.emitAlert({
            theme: Theme.ERROR,
            title: "No Slots Available!",
            message:  "All slots for the selected dates has been full",
            ctaLabel: 'Ok!'
          })
          this.hourDropDownDetails.isDisabled = true;
          reject(true);
        }
        else{
          this.hourDropDownDetails.options =  this.setHours(this.allSlotsData);
          this.hourDropDownDetails.isDisabled = false;
          resolve(true);
        }
    }
    else{
      this._commonSubscription.emitAlert({
        theme: Theme.ERROR,
        title: "Technical Error!",
        message: res['message'],
        ctaLabel: 'Ok!'
      });
      reject(true);
    }
  });
})
    return x;
  }
  

  setHours(timeSlots){
    let availableSlotsHours = [];
    let leadTime = this.handleLeadTime().getTime();
    console.log(leadTime)
    timeSlots.forEach(timeGroup=>{
      Object.keys(timeGroup.slots).forEach(slot=>{
  
        if(timeGroup['slots'][slot]['slotStatus'] == 'AVAILABLE' && timeGroup['slots'][slot]['slotTime'] >= leadTime){
            let hour = slot.split(":")[0];
            if(availableSlotsHours.length < 1){
              availableSlotsHours.push({label : hour, value: hour});
            }
            else{
              let availableSlotsArr = availableSlotsHours.map(slots=>slots.value);
              if(!availableSlotsArr.includes(hour)){
                availableSlotsHours.push({label : hour, value: hour});
              }
              else{
                return;
              }
            }  
        }

        else{
          return;
        }
      });
    });
    return availableSlotsHours;
  }

  onHoursChange(){
   this.minDropDownDetails.options =  this.setMinutes(this.hourDropDownDetails.selectedValue.value, this.allSlotsData);
   if(this.minDropDownDetails.options.length < 1){
    this._commonSubscription.emitAlert({
      theme: Theme.WARNING,
      title: 'No Slots Available!',
      message: 'Slots are not available for given time',
      ctaLabel: 'Change Slot'
    });
    this.minDropDownDetails.isDisabled = true;
   }
   else{
    this.minDropDownDetails.isDisabled = false;
   } 
  }

  setMinutes(selectedHour, timeSlots){
      
      let availableSlotsMin = [];
      let leadTime = this.handleLeadTime().getTime();
      
      timeSlots.forEach(timeGroup=>{  
        Object.keys(timeGroup.slots).forEach(slot=>{
          console.log(timeGroup['slots'][slot], leadTime)
          if(slot.split(":")[0] == selectedHour && timeGroup['slots'][slot]['slotStatus'] == 'AVAILABLE' && timeGroup['slots'][slot]['slotTime'] >= leadTime){
            availableSlotsMin.push({label: slot.split(":")[1], value: slot.split(":")[1]});
          }
          else{
            return;
          }
        })
      });

      return availableSlotsMin;
  }

  handleLeadTime(){
    let startDate = new Date();
    let leadTime = this.getLeadTime();
    startDate.setMinutes(startDate.getMinutes() + leadTime);
    startDate.setSeconds(0);
    startDate.setMilliseconds(0);

    if(this._common.getIsAdminUser()){
      return startDate;
    }

    else{
      let extraBuffer = this.getExtraBufferTime(startDate.getMinutes());
      extraBuffer == 0 ? startDate.setHours(startDate.getHours() + 1) : startDate.setHours(startDate.getHours());
      startDate.setMinutes(extraBuffer);
      return startDate;
    }    
  }

  getLeadTime(){
    if(this._common.getIsAdminUser()){
      return 0;
    }
    else {
      return this._common.getLeadTime();
    }
  }


  getExtraBufferTime(minVal){
    let buffer = 0;
    for(let i = 0; i < 60; i=i+this.timestampInputDetails.stepMinute){
      if(minVal >= i && minVal < i + this.timestampInputDetails.stepMinute ){  
        buffer = i + this.timestampInputDetails.stepMinute;
      }
      else{
        buffer = buffer;
      }
    }
    return buffer;
  }
  

  setMaxDate(){
    let maxDate = new Date();
      //changed the addition of days from 3 to 30 as per ThomasCook requirements
      maxDate.setDate(maxDate.getDate() + 30);
      maxDate.setHours(23, 0, 0, 0);

      return maxDate;
  }

  fetchPrevData(){
    this.timestampInputDetails.inputValue = null;
    this.checkIfSlotsRequired();
    if(this.timestampInputDetails.inputValue == null){
        return;
      }

      else{
        if(this.isSlotsRequired){
          this.fetchSlots(this.timestampInputDetails.inputValue).then(res=>{
            this.onHoursChange();
          });

        this.hourDropDownDetails.selectedValue = {
          label: this.timestampInputDetails.inputValue?.getHours().toString().length < 2 ? `0${this.timestampInputDetails.inputValue.getHours()}` : `${this.timestampInputDetails.inputValue.getHours()}` , 
          value: this.timestampInputDetails.inputValue?.getHours().toString().length < 2 ? `0${this.timestampInputDetails.inputValue.getHours()}` : `${this.timestampInputDetails.inputValue.getHours()}`
        }
  
  
        this.minDropDownDetails.selectedValue = {
          label: this.timestampInputDetails.inputValue?.getMinutes().toString().length < 2 ? `0${this.timestampInputDetails.inputValue.getMinutes()}` : `${this.timestampInputDetails.inputValue.getMinutes()}` , 
          value: this.timestampInputDetails.inputValue?.getMinutes().toString().length < 2 ? `0${this.timestampInputDetails.inputValue.getMinutes()}` : `${this.timestampInputDetails.inputValue.getMinutes()}`
        }
      }

      else{
        return ;
      }
    }
    
  }
}
