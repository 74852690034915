
import { Observable } from 'rxjs';
import { API_URL_LOGIN,API_URL_LOGIN_V2, API_URL_CHECK_TOKEN, API_URL_LOGOUT, API_URL_GET_ROLES_FOR_USERS, API_URL_GET_RIDER_DELAY_ALERT, API_URL_SELECT_ZONE, API_URL_MFA_SEND_OTP, API_URL_MFA_VERIFY_OTP, API_URL_LOGIN_WITH_SSO } from '../config/apiRouteConfig/index';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable, LOCALE_ID } from '@angular/core';
import { Router } from '@angular/router';
import { BusinessDetailsService } from './business-details/business-details.service';

@Injectable()
export class AppService {
  isValid: boolean = false;
  isLoad: boolean = false;
  currentServiceRegionId = [];
  rideType:any;
  roles = 'roles'
  accntDetails = {};
  credentials = {};
  loginAttemptsCounter = 0;
  isRosterUploadEnabled = false;

  constructor(private _http: HttpClient, @Inject(LOCALE_ID) public locale: string, private router:Router, private _business: BusinessDetailsService) { }

  getDelayAlerts(){
    return this._http.get(API_URL_GET_RIDER_DELAY_ALERT)
  }

  getCurrentServiceRegionId() {
    return this.currentServiceRegionId;
  }
  setCurrentServiceRegionId(serviceRegionId:any) {
    this.currentServiceRegionId = serviceRegionId;
  }

  getRideType() {
    return this.rideType;
  }
  setRideType(rideType:any) {
    this.rideType = rideType;
  }

  login(data:any): Observable<any> {
    return this._http.post(API_URL_LOGIN, data);
  }
  loginV2(data:any): Observable<any> {
    return this._http.post(API_URL_LOGIN_V2, data);
  }
  checkToken() {
    return this._http.get(API_URL_CHECK_TOKEN);
  }
  logout() {
    return this._http.post(API_URL_LOGOUT, {});
  }
  getRolesForUser(ssoId:any){
    return this._http.get(API_URL_GET_ROLES_FOR_USERS+ssoId+"/roles")
  }
  getRolesForUserSync(ssoId):Promise<any>{
    return this._http.get(API_URL_GET_ROLES_FOR_USERS+ssoId+"/roles").toPromise()
  }
  getCountryZones(){
    return this._http.get(API_URL_SELECT_ZONE);
  }
  getCountry(){
    return this.locale.split('-')[1];
  }
  setAccountDetails(accntDetails){
    this.accntDetails =  accntDetails;
  }
  getAccountDetails(){
    return this.accntDetails;
  }
  sendMfaOtp(data){
    return this._http.post(API_URL_MFA_SEND_OTP , data);
  }
  setCredentials(reqData){
    this.credentials = reqData;
  }

  getCredentials(){
    return this.credentials;
  }

  setLoginAttempts(attempts){
    if(attempts != 'Unsuccessful attempts reached, please reset your password'){
      this.loginAttemptsCounter++;
    }
    else{
      return ;
    }
  }

  getLoginAttempts(){
    return this.loginAttemptsCounter;
  }


  verifyMfaOtp(data){
    return this._http.post(API_URL_MFA_VERIFY_OTP, data);
  }

  setValuesInLocalStorage(data){
    localStorage.removeItem('accessToken');
    
    localStorage.setItem('accessToken',data.accessToken);
    localStorage.setItem('businessAccountId',data.businessAccountId);
    this._business.setBusinessAccountId(data.businessAccountId);

    this.checkToken().subscribe(res=>{
      if(res['response']['accountInfo']['businessAccountDataObj']){
        if(res['response']['accountInfo']['businessAccountDataObj']['isAccountSetupRequired']){
          this.router.navigateByUrl('/setup');
        }
        else{
          this.router.navigateByUrl('/');  
        }
      }
      else{
        this.router.navigateByUrl('/');
      }})
  }

  loginWithSso(){
    return this._http.get(`${API_URL_LOGIN_WITH_SSO}/okta/login`);
  }

}
