import { HttpClient } from '@angular/common/http';
import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { HomepageApiService } from 'src/app/app-v2/api-services/homepage/homepage-api.service';
import { CountryFeatureListService } from 'src/app/app-v2/localization/country-feature-list/country-feature-list.service';
import { blrBounds } from 'src/app/utils/service-region-BLR';
import { ncrBounds } from 'src/app/utils/service-region-NCR';




@Component({
  selector: 'serviceable-regions-container',
  templateUrl: './serviceable-regions-container.component.html',
  styleUrls: ['./serviceable-regions-container.component.scss']
})
export class ServiceableRegionsContainerComponent implements OnInit ,OnChanges{

  @Input() selectedZone = {};
  @Input() allServiceRegions = {};
  zoneBasedAllBounds = {};
  zoneBasedDefaultCoordinates = {};
  selectedRideType:string = "SCHEDULED";
  
  selectedBounds = {pick: [], drop: []};

  constructor(private _homepageApi: HomepageApiService, private _http: HttpClient, private _countryFeature: CountryFeatureListService){}

  ngOnInit(): void {
  }
  

  ngOnChanges(changes: SimpleChanges): void {
   this.fetchAllBounds();
  }

  selectRideType(type){
    this.selectedRideType = type;
    this.setBounds();
  }

  fetchAllBounds(){
    this.zoneBasedAllBounds = this.selectedZone['value'] == 1 ? ncrBounds : this.selectedZone['value'] == 2 ? blrBounds : null;
    this.zoneBasedDefaultCoordinates = this._countryFeature.getCountryFeatureList()['zoneBasedDefaultCoordinates'][`${this.selectedZone['value']}`];
    this.setBounds();
  };

  setBounds(){
    this.selectedBounds = {pick: [], drop: []}
    switch(this.selectedRideType){
      case 'SCHEDULED':
      this.selectedBounds.pick = this.getSelectedBounds(['ridesPickUp']);
      this.selectedBounds.drop = this.getSelectedBounds(['ridesDrop']);
      return ;

      case 'RENTAL':
      this.selectedBounds.pick = this.getSelectedBounds(['cityRentalsPickup', 'rentalsPickUp']);
      this.selectedBounds.drop = this.getSelectedBounds(['cityRentalsDrop', 'rentalsDrop']);
      return ;

      case 'AIRPORT':
        this.selectedBounds.pick = this.getSelectedBounds(['airportPickDrop','airportPickupPick', 'airportPickupDrop' ]);
        this.selectedBounds.drop = this.getSelectedBounds(['airportPickDrop','airportDropDrop','airportDropPickup']);
    }

      
  }

  getSelectedBounds(code){
    let tempArr = [];
    let indexes = Object.keys(this.zoneBasedAllBounds);

    for(let i =0; i <indexes.length; i++){
      if(code.includes(this.zoneBasedAllBounds[`${i}`]['id'])){
        tempArr.push(this.zoneBasedAllBounds[`${i}`]['bounds']);
      }
      else{
        null;
      }
    }

    return tempArr;
  }

}
