import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { API_URL_FETCH_HOMEPAGE_DATA, API_URL_GET_RIDER_DELAY_ALERT } from 'src/app/config/apiRouteConfig';

@Injectable({
  providedIn: 'root'
})
export class HomepageApiService {

  constructor(private _http: HttpClient) { }

  getDelayAlerts(){
    return this._http.get(API_URL_GET_RIDER_DELAY_ALERT);
  }


  fetchHomescreenData(){
    return this._http.get(API_URL_FETCH_HOMEPAGE_DATA);
}}
