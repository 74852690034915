/// <reference types="@angular/localize" />

import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

let googleApiKey = "https://maps.googleapis.com/maps/api/js?language=en&key="+environment.googlePlacesAPIKey+"&libraries=places";

let script = document.createElement('script');
script.type = 'text/javascript';
script.src = googleApiKey;
document.getElementsByTagName('head')[0].appendChild(script);


if (environment.production) {
  enableProdMode();
}


platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
