import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TextAreaDetails } from '../../types/components.type';

@Component({
  selector: 'custom-text-area',
  templateUrl: './text-area.component.html',
  styleUrls: ['./text-area.component.scss']
})
export class TextAreaComponent {
  @Input() textAreaDetails: TextAreaDetails;
  @Output() onSendTextAreaData = new EventEmitter<string>();



  sendTextAreaData(){

  }
}

