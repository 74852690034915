<div class="textArea-input">
    <span class="field-label">
        <div class="field-label-container">
            {{textAreaDetails ? textAreaDetails.label : null}}
            <sup *ngIf="textAreaDetails.required">*</sup>
        </div>
        
        <div class="tooltip-container">
            <custom-tooltip *ngIf="textAreaDetails.enableTooltip" [tooltipText]="textAreaDetails.tooltipContent"></custom-tooltip>
        </div>
        
    </span>
    
    <div class="textArea-container" [ngClass]="{'textArea-error' : textAreaDetails?.validation?.isError, 'textArea-success' : textAreaDetails?.validation?.isSuccess, 'textArea-disabled' : textAreaDetails.isDisabled}">
        <textarea class="custom-textArea" [placeholder]="textAreaDetails.placeholder" [(ngModel)]="textAreaDetails.inputValue" [disabled]="textAreaDetails.isDisabled" [ngClass]="{'textArea-disabled' : textAreaDetails.isDisabled}"
          (input)="sendTextAreaData()" [rows]="textAreaDetails.rows" [cols]="textAreaDetails.columns"></textarea>
    </div>
    
        <div class="validator-container" *ngIf="textAreaDetails.validation != null">
        <validator [validatorDetails]="textAreaDetails.validation"></validator>
        </div>
    </div>