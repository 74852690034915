import { Component, OnInit } from '@angular/core';
import { RiderService } from '../../../../services/rider/rider.service';


@Component({
  selector: 'app-tnc',
  templateUrl: './tnc.component.html',
  styleUrls: ['./tnc.component.css']
})
export class TncComponent implements OnInit {

  constructor(private _riderService: RiderService) {

    _riderService.getTnCInfo().subscribe(response=>document.getElementById('container').innerHTML = response['response']['RENTAL_CANCELLATION_WAITING_CHARGES_POLICY'])
  }

  ngOnInit() {
  }

}
