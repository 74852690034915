<div class="main-container">
    <span class="ride-type-title">Select ride type</span>
    
    <div class="select-ride-type-container">
        <div class="ride-type-container" *ngFor="let rideType of allowedRideTypes; index as i">
            <div [ngClass]="{'ride-type-selected': rideType?.value == selectedRideType , 'ride-type-unselected': rideType?.value != selectedRideType }" (click)="selectRideType(i)">
                <img [src]="rideType?.url" alt="">
                <span>{{rideType?.name}}</span>
            </div>
        </div>
    </div>

    <div class="pricing-table">
        <table class="price-table-container">
            <tr class="table-headers">
                <th *ngFor="let field of selectedDataFields" [ngStyle]="{width: field.width}">
                    {{field.label}}
                </th>
            </tr>


            <tr *ngFor="let price of selectedPricingData; index as i" class="table-content">
                <td [ngStyle]="{width: getColumnWidth('S.No')}">{{startIndex + i + 1}}</td>

                <td *ngFor="let item of price" [ngStyle]="{width: getColumnWidth(item['field'])}">
                    <span *ngIf="item['type'] == 'price'">{{ item['value'] | currencypipe }}</span>    
                    <span *ngIf="item['type'] != 'price'">{{ item['value'] }}</span>    
                </td>
            </tr>

        </table>
        
    </div>

    <div class="controls-slide">
        <img src="assets/backward.svg" alt="" (click)="slide('back')">
        <img src="assets/forward.svg" alt="" (click)="slide('forward')">
    </div>


    <div class="price-note" *ngIf="selectedFieldNotes.length > 0">
        <span class="notes-title">{{notesTitle}}</span>
        <span *ngFor="let item of selectedFieldNotes" class="notes-text">
            {{item}}
        </span>
    </div>


</div>