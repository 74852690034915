export class PermissionForUsers {
    public static checkPermission(rolePermission: any): boolean {
      try {
        const roles = localStorage.getItem("userRoles") || "";
        const data = JSON.parse(roles);
        return data && data.includes(rolePermission);
      }
      catch(e) {
           console.error(e);
           return false;
      }
    }
}
