import { Injectable } from "@angular/core";
import { API_URL_ADD_SUB_PLACES, API_URL_LOCATION_METADATA } from "src/app/config/apiRouteConfig";

import { HttpClient } from "@angular/common/http";
import { Observable, of } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class LocationService {
  constructor(private _http: HttpClient) {}

  getSubPlaces(body){
     return  this._http.post(API_URL_ADD_SUB_PLACES, body); 
}

getLocationMetadata(body){
  return  this._http.post(API_URL_LOCATION_METADATA, body); 
 } 
}
