<div class="main-container">

    <div class="calendar-main-container">
        <span class="date-title" *ngIf="isSlotsRequired">Select Date</span>

        <div class="time-stamp-container">
            <custom-input [inputBoxDetails]="timestampInputDetails" [inputType]="'CALENDAR'" (onSelectDate)="setTimestamp($event)" (onClearInput)="clearTimeStampDetails()" (onDatePanelVisible)="panelVisible()"></custom-input>
        </div>
    </div>
    


    <div class="slots-main-container" *ngIf="isSlotsRequired">
        <span class="slot-title">Select Slot</span>

        <div class="slots-container">
            <div class="hours-container">
                <dropdown [dropdownDetails]="hourDropDownDetails" (changehandler)="onHoursChange()"></dropdown>
            </div>
:
            <div class="min-container">
                <dropdown [dropdownDetails]="minDropDownDetails"></dropdown>
            </div>  
        </div>
    </div>

    <div class="time-stamp-cta-container">
        <custom-button [buttonDetails]="timeStampContainerCta" (onCustomButtonClick)="timeStampCtaClick()"></custom-button>
    </div>   
</div>