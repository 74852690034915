import { Injectable } from '@angular/core';
import { Subject, timestamp } from 'rxjs';
import { CreateRideSubscriptionService } from 'src/app/app-v2/subscriptions/create-ride/create-ride-subscription.service';
import { JourneyDetailsPayloadDto, Location } from 'src/app/app-v2/types/container.type';
import { journeyDetails } from 'src/app/app-v2/validations/createRide/create-ride-validation';
import { CreateRideValidationService } from 'src/app/app-v2/validations/createRide/create-ride-validation.service';
import { SelectRideTypeContainerService } from './select-ride-type-container.service';

@Injectable({
  providedIn: 'root'
})
export class JourneyDetailsContainerService {

  journeyDetails: JourneyDetailsPayloadDto = {
    pickupLat: null,
    pickupLong: null,
    pickupLocation: null,
    dropLat: null,
    dropLong:null,
    dropLocation: null,
    stops: [],
    map: "",
    platform: 'WEB',
    startTime: null,
    priceMapId: null,
    otherFlags: {
      businessRideReason : '',
      expenseCode: ''
    },
    packageCode: null,
    rideSubCategory: null,
    pickupNotes: null
  }

  selectedVariantCategory = "";

  addLocationExtraFields = {
    PICK : {
      isAirportLocation : false,
      landmarks : [],
      selectedLandmarkIndex : 0,
      zoneId: 0
    },
    DROP:{
      isAirportLocation : false,
      landmarks : [],
      selectedLandmarkIndex : 0,
      zoneId: 0
    },
    STOPS : []
  }  

  
  journeyDetailsValidation = {
    addLocation : false,
    additionalDetails : false,
    carVariantDetails: false,
    timestampDetails: false
  }

  selectedZoneIdForLeadTime = {id: 0, isChanged: false};

  journeyAccDetails = {
    locationDetails : {  
      label : "Enter pickup/drop locations, pickup notes and business ride reason", 
      isActive: false,
      isComplete: false,
      isDisabled: true,
      isVisible: true
     },

carVariantDetails : { 
       label : "Select car variant",
       isActive: false, 
       isComplete: false, 
       isDisabled: true, 
       isVisible: false,
     },

timestampDetails : { 
     label : "Select date and time" ,
     isActive:  false,
     isComplete: false,
     isDisabled: true,
     isVisible: true,
     }
}


  constructor(private _createRideValidation : CreateRideValidationService) {}
  
  getLocationAccDetails(){
    this.journeyAccDetails.locationDetails.isComplete = false;
    return this.journeyAccDetails.locationDetails;
  }

  getCarvariantAccDetails(){
    this.journeyAccDetails.carVariantDetails.isComplete = false;
    return this.journeyAccDetails.carVariantDetails;
  }

  getTimestampAccDetails(){
    return this.journeyAccDetails.timestampDetails;
  }


  setPickupLocation(coordinates){
    this.journeyDetails.pickupLat = coordinates.lat;
    this.journeyDetails.pickupLong = coordinates.lng;
    this.journeyDetails.pickupLocation = coordinates.place;
  }

  getPickupLocation(){
    let pickUpDto = {
      lat: this.journeyDetails.pickupLat,
      lng: this.journeyDetails.pickupLong,
      place: this.journeyDetails.pickupLocation
    }
    return pickUpDto;
  }

  setDropLocation(coordinates){
    this.journeyDetails.dropLat = coordinates.lat;
    this.journeyDetails.dropLong = coordinates.lng;
    this.journeyDetails.dropLocation = coordinates.place;
  }

  getDropLocation(){
    let dropDto = {
      lat: this.journeyDetails.dropLat,
      lng: this.journeyDetails.dropLong,
      place: this.journeyDetails.dropLocation
    }

    return dropDto;
  }

  setSelectedZoneIdForLeadTime(){
    this.selectedZoneIdForLeadTime.isChanged = this.selectedZoneIdForLeadTime.id == this.addLocationExtraFields.PICK.zoneId ? false : true; 
    this.selectedZoneIdForLeadTime.id = this.addLocationExtraFields.PICK.zoneId;
    console.log(this.selectedZoneIdForLeadTime);
  }

  getSelectedZoneIdForLeadTime(){
    return this.selectedZoneIdForLeadTime;
  }

  setStops(stops){
    this.journeyDetails.stops = stops; 
  }

  getStops(){
    return this.journeyDetails.stops;
  }

  setPackageCode(code){
    this.journeyDetails.packageCode = code;
  }

  getPackageCode(){
    return this.journeyDetails.packageCode;
  }

  setPriceMapId(id){
    this.journeyDetails.priceMapId = id;
  }

  getPriceMapId(){
    return this.journeyDetails.priceMapId;
  }

  setSubCategory(category){
    this.journeyDetails.rideSubCategory = category;
  }

  getSubCategory(){
    return this.journeyDetails.rideSubCategory;
  }

  setPickupLocationExtraFields(extraData){
    this.addLocationExtraFields.PICK.isAirportLocation = extraData.isAirportLocation;
    this.addLocationExtraFields.PICK.landmarks = extraData.landmarks;
    this.addLocationExtraFields.PICK.zoneId = extraData.zoneId;
    this.addLocationExtraFields.PICK.selectedLandmarkIndex = extraData.selectedLandmarkIndex;
  }

  setDropLocationExtraFields(extraData){
    this.addLocationExtraFields.DROP.isAirportLocation = extraData.isAirportLocation;
    this.addLocationExtraFields.DROP.landmarks = extraData.landmarks;
    this.addLocationExtraFields.DROP.zoneId = extraData.zoneId;
    this.addLocationExtraFields.DROP.selectedLandmarkIndex = extraData.selectedLandmarkIndex;
  }

  setStopsExtraFields(stopsExtraFields){
    this.addLocationExtraFields.STOPS = [];
    this.addLocationExtraFields.STOPS = stopsExtraFields;
  }

  getPickupLocationExtraFields(){
    return this.addLocationExtraFields.PICK;
  }

  getDropLocationExtraFields(){
    return this.addLocationExtraFields.DROP;
  }

  getStopsLocationExtraFields(){
    return this.addLocationExtraFields.STOPS;
  }


  setPackageContainerDetails(packageDetails){
    this.journeyDetails.priceMapId = packageDetails.priceMapId;
    this.journeyDetails.packageCode=packageDetails.packageCode;
  }

  getPackageContainerDetails(){
    let packageContainerDetailsDto={
      priceMapid : this.journeyDetails.priceMapId,
      packageCode: this.journeyDetails.packageCode,
    }
    return packageContainerDetailsDto;
  }

  setTimestampDetails(timestamp){
    this.journeyDetails.startTime = timestamp;
  }

  getTimestampDetails(){
    return this.journeyDetails.startTime;
  }

  getJourneyDetailsToRequestRide(){
    return this.journeyDetails;
  }

  setAddtionalDetails(otherflags, pickupNotes){
    let pickupNotesDto ={
      createdTimestamp: new Date().getTime(),
      isForAll: false,
      lastUpdatedTimestamp: new Date().getTime(),
      latitude: this.journeyDetails.pickupLat,
      longitude: this.journeyDetails.pickupLong,
      notes: pickupNotes
    }
      this.journeyDetails.otherFlags = otherflags,
      this.journeyDetails.pickupNotes = pickupNotes == '' ? null : pickupNotesDto;
  }


  async checkValidation(field: string, index: number = 0){
    switch(field){
      case 'PICK':
        var x = await this._createRideValidation.validatePickupLocation(this.journeyDetails.pickupLocation);
        return x;
        
      case 'STOPS':
        var x = await this._createRideValidation.validateStopLocation(this.journeyDetails.stops[index] ? this.journeyDetails.stops[index]['place'] : null);
        return x;  
    
      case 'DROP':
        var x = await this._createRideValidation.validateDropLocation(this.journeyDetails.dropLocation);
        return x;  

      case 'businessRideReason':
        var x = await this._createRideValidation.validateBusinessRideReason(this.journeyDetails.otherFlags.businessRideReason);
        return x;   
        
      case 'expenseCode':
        var x = await this._createRideValidation.validateExpenseCode(this.journeyDetails.otherFlags.expenseCode);
        return x;   
    }
  }


  setSelectedCarVariantCategory(category){
    this.selectedVariantCategory = category;
  }

  getSelectedCarVariantCategory(){
    return this.selectedVariantCategory;
  }

  setAddLocationValidation(validation){
    this.journeyDetailsValidation.addLocation = validation;
  }

  getAddLocationValidation(){
    return this.journeyDetailsValidation.addLocation;
  }

  setAdditionalDetailsValidation(validation){
    this.journeyDetailsValidation.additionalDetails = validation;
  }

  getAdditionalDetailsValidation(){
    return this.journeyDetailsValidation.additionalDetails;
  }

  setCarVariantValidation(validation){
    this.journeyDetailsValidation.carVariantDetails = validation;
  }

  getCarVariantValidation(){
    return this.journeyDetailsValidation.carVariantDetails;
  }

  getPickupLocationZoneId(){
    return this.addLocationExtraFields.PICK.zoneId;
  }

  clearJourneyDetails(){
    this.journeyDetails={
    pickupLat: null,
    pickupLong: null,
    pickupLocation: null,
    dropLat: null,
    dropLong:null,
    dropLocation: null,
    stops: [],
    map: "",
    platform: 'WEB',
    startTime: null,
    priceMapId: null,
    otherFlags: {
      businessRideReason : '',
      expenseCode: ''
    },
    packageCode: null,
    rideSubCategory: null,
    pickupNotes: null
  }

  this.selectedZoneIdForLeadTime = {id: 0, isChanged : false};
  this.clearAddLocationExtraFields();
  this.clearJourneyAccDetails();
}

clearJourneyAccDetails(){
  this.journeyAccDetails = {
    locationDetails : {  
      label : "Enter pickup/drop locations, pickup notes and business ride reason", 
      isActive: false,
      isComplete: false,
      isDisabled: true,
      isVisible: true
     },

carVariantDetails : { 
       label : "Select car variant",
       isActive: false, 
       isComplete: false, 
       isDisabled: true, 
       isVisible: false,
     },

timestampDetails : { 
     label : "Select date and time" ,
     isActive:  false,
     isComplete: false,
     isDisabled: true,
     isVisible: true,
     }
  }
}


clearAddLocationExtraFields(){
  this.addLocationExtraFields = {
    PICK : {
      isAirportLocation : false,
      landmarks : [],
      selectedLandmarkIndex : 0,
      zoneId: 0
    },
    DROP:{
      isAirportLocation : false,
      landmarks : [],
      selectedLandmarkIndex : 0,
      zoneId: 0
    },
    STOPS : []
  }  
}

getBusinessRideReason(){
  return this.journeyDetails.otherFlags.businessRideReason == "" ? null : this.journeyDetails.otherFlags.businessRideReason;
}

getExpenseCode(){
  return this.journeyDetails.otherFlags.expenseCode  == "" ? null : this.journeyDetails.otherFlags.expenseCode;
}

getPickupNotes(){
  return this.journeyDetails.pickupNotes ? this.journeyDetails.pickupNotes.notes : null;
}

getRideTypeCategory(){
let pickupType = this.addLocationExtraFields.PICK.isAirportLocation;
let dropType = this.addLocationExtraFields.DROP.isAirportLocation;
let stopsType = this.addLocationExtraFields.STOPS.map(stop=>stop.isAirportLocation);

if(pickupType && (dropType|| stopsType.includes(true))){
  return 'a2A';
}

else if(!pickupType && (dropType|| stopsType.includes(true))){
  return 'c2A';
}

else if(pickupType && (!dropType|| !stopsType.includes(true))){
return 'a2C';
}

else{
  return 'c2C';
}

}


}
