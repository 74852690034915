import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { CommonSubscriptionService } from '../../subscriptions/common/common-subscription.service';

@Component({
  selector: 'home-page',
  templateUrl: './home-page.component.html',
  styleUrls: ['./home-page.component.scss']
})
export class HomePageComponent implements OnInit, OnDestroy{
  constructor(private _commonSubscription: CommonSubscriptionService){}

  ngOnInit(): void {
  this._commonSubscription.emitDelayAlert(false);
  }
  
  
  ngOnDestroy(): void {
    this._commonSubscription.emitDelayAlert(true);
  }

}
