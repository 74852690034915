import { Component } from '@angular/core';

@Component({
  selector: 'create-shared-ride-page',
  templateUrl: './create-shared-ride-page.component.html',
  styleUrls: ['./create-shared-ride-page.component.scss']
})
export class CreateSharedRidePageComponent {

}
