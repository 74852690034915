import {
  API_URL_LIST_DRIVERS ,
  API_URL_ADD_DRIVER,
  API_URL_EDIT_DRIVER,
  API_URL_RESET_PASSWORD_DRIVER,
  API_URL_GET_PUNCH_ID,
  API_URL_DRIVER_STATES_AUDIT,
  API_URL_DRIVER_TEMPERATURE,
  API_OVERSPEEDING_REPORT,
  API_OVERSPEEDING_REPORT_PANEL,
  API_OVERSPEEDING_DRIVER,
  API_URL_GET_DRIVER_LOCATION,
} from '../../config/apiRouteConfig/index';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';


@Injectable()
export class DriverService {
  getDriverLocation(driverId: any): any {
    return this._http
    .get(API_URL_GET_DRIVER_LOCATION+"/"+driverId+"/location").toPromise();
  }
 
  callListApi= true;

  constructor(private _http: HttpClient) { }

  getDrivers(): Observable<any>{
    return this._http
    .get(API_URL_LIST_DRIVERS);
  }

  addDriver(data){
    return this._http.post(API_URL_ADD_DRIVER, data);
  }

  editDriver(data): Observable<any>{
     return this._http.put(`${API_URL_EDIT_DRIVER}/${data.driverId}`, data);
  }
  resetPassword(data): Observable<any>{
    return this._http.post(`${API_URL_RESET_PASSWORD_DRIVER}/${data.driverId}`, data);
  }

  getDriversDetails(data){
    return this._http.get(`${API_URL_GET_PUNCH_ID}/${data}`)
  }
  getDriverStatesAudit(startDate,endDate){
    return this._http.get(`${API_URL_DRIVER_STATES_AUDIT}` + '?start=' + startDate + '&end=' + endDate);
  }

  getDriverTemperature(): Observable<any> {
    return this._http.get(`${API_URL_DRIVER_TEMPERATURE}`);
  }
  // getDriverOverSpeedingDataMockey() : Observable<any> {
  //   return this._http.get(`https://run.mocky.io/v3/e9849b3f-bfc3-439c-b16a-f83be40c70ee`)
  // }
  // getDriverOverSpeedingData({from, to}) : Observable<any> {
  //     return this._http.get(API_OVERSPEEDING_REPORT + '/from/' + from + '/to/' + to); 
  // }
  getDriverOverSpeedingPanelData(from, to,driverId) : Observable<any> {
    return this._http.get(API_OVERSPEEDING_REPORT_PANEL + 'from/' + from + '/to/' + to + '/driverId/' + driverId);
}
getDriverOverSpeedingStatsForRange( from, to ) {
  return this._http.get(API_OVERSPEEDING_DRIVER + '/from/' + from + '/to/' + to);
}

}

