<div class="main-container">

    <div class="paymentMainContainer">
        <img src="../../../../../assets/paymentIcon.svg" alt="">
        <h3 style="font-size: 1.4rem;">Clear Your Pending Payments</h3>
        <p style="color: #707070; font-size: 0.8rem;">Please complete the payment of <span style="color: black; font-size: 1rem;">{{totalPayment | currencySymbol}}</span> from your previous trips to request a ride</p>
  
        <div class="paymentListContainer">
        <p-dropdown [options]="paymentList" [(ngModel)]="selectedPaymentMethod" optionLabel="name" appendTo="body"
        [showClear]="false" placeholder="Choose" class="dropdownContainer" [ngClass]="{dropdownContainerError: isError}">
          <ng-template pTemplate="selectedItem" let-selectedOption>
            <div class="selectedPaymentList">
                   <img [src]="selectedOption.img" style="width: 25px"/> 
                  <div>{{ selectedOption.name }}</div>
              </div>
          </ng-template>
  
          <ng-template let-paymentMode pTemplate="item">
              <div class="paymentList">
                  <div style="display: flex; flex-direction: row; align-items: center; gap: 1rem;">
                  <img [src]="paymentMode?.img" style="width: 25px;"/>
                  <div>{{ paymentMode?.name }}</div>
                </div>
  
                  <div>
                    <input class="radio-selection" type="radio" name="paymentMode" [value]="paymentMode?.code" id="" [(ngModel)]="selectedPaymentMethod.code">
                  </div>
  
              </div>
          </ng-template>
      </p-dropdown>
    </div>

      <div class="errorContainer" *ngIf="isError">
      <img src="../../../../../assets/errorIcon.svg" alt="">  
      <p>{{errorMessage}}</p>
      </div>
  
      <button class="payCta" (click)="confirmPayment()" *ngIf="!addMoney">Pay</button>
      <button class="payCta" (click)="addMoneyToWallet()" *ngIf="addMoney">Add Money To Wallet</button>
      </div>
      
</div>