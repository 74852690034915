import { RouterModule, Routes, ExtraOptions } from '@angular/router';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from '../header/header.component';

//import { FormComponent } from './../ticket/form/form.component';

import { DialogModule } from 'primeng/dialog';
import { StepsModule } from 'primeng/steps';
import { FormsModule } from '@angular/forms';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import {  MessageService } from 'primeng/api';
import { ToastModule } from 'primeng/toast';
import { DropdownModule } from 'primeng/dropdown';
import { MultiSelect, MultiSelectModule } from 'primeng/multiselect';
import { CommonsModule } from '../../common/common.module';

const route: Routes = [
  { path: '**', redirectTo: 'rides' }
];

const routerOptions: ExtraOptions = {
  onSameUrlNavigation: 'reload',
};

@NgModule({
  declarations: [
    HeaderComponent
  ],
  imports: [
    CommonModule,
    RouterModule.forRoot(route, routerOptions),
    DialogModule,
    StepsModule,
    ProgressSpinnerModule,
    ToastModule,
    DropdownModule,
    FormsModule,
    MultiSelectModule,
    CommonsModule
  ],
  providers:[MessageService],
  exports:[HeaderComponent]
})
export class HeaderModule { }
