<div class="main-container">
    <div class="package-container" *ngIf="isPackageRequired">
        <dropdown [dropdownDetails]="packageDropDownDetails" (changehandler)="onPackageSelect()"></dropdown>
    </div>

    <div class="car-variant-container">
        <div *ngFor="let variant of carVariantDetails; index as i" (click)="selectVariant(i)" class="variant-container">
            <card type="container" [isActive]="variant['isActive']" isShowSelectionLabel="true" >
                <div container class="category-container">
                    <img [src]="variant['carUrl']" alt="" class="variant">
                    <span class="car-label">{{variant['carLabel']}}</span>
                    <div [ngClass]="{'classic-car-category' : variant['category'] == 'CLASSIC', 'premium-car-category': variant['category'] == 'PREMIUM'}">
                        <span class="car-category" [ngClass]="{classic: variant['category'] == 'CLASSIC', premium: variant['category'] == 'PREMIUM'}">{{variant['category']}}</span>
                    </div>
                    <div class="extra-points-container">
                        <div *ngFor="let extraPoint of variant['extraPoints']" class="align-extrapoints">
                            <div [ngClass]="{'classic-extra-points' : variant['category'] == 'CLASSIC', 'premium-extra-points': variant['category'] == 'PREMIUM'}">
                            <img [src]="extraPoint['url']" alt="">
                            </div>
                            <span class="extra-points-label">{{extraPoint['label']}}</span>
                        </div>
                    </div>
                </div>
            </card>
        </div>
        
    </div>

    <div class="car-variant-cta-main-container">
        <div class="car-variant-cta-container">
            <custom-button [buttonDetails]="carVariantCta" (onCustomButtonClick)="submitCarVariantDetails()"></custom-button>
        </div>   
    </div>
    
</div>
