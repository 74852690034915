<div class="mainContainer">
    <img src="../../../assets/backBtn.svg" class="backBtn" width="3%" *ngIf="showBackBtn" (click)="prevScreen()">
  
    <div class="topSection">
      <img src="../../../assets/blu_logo.svg" alt="" class="logo" />
      <p class="title">{{ title }}</p>
      <p class="contentContainer" [innerHTML]="content"></p>
  
      <!-- directed from locked account -->
      <div class="lockedUserContainer" *ngIf="!throughForgetPassword">
        <img width='25%' src='../../../assets/lockeduser.svg'/><br>
        <p>Your Account has been locked due to 5 unsuccessful attempts. To unlock, kindly reset your password.</p>
        <button pButton type="button" label="Reset Password" class="enableBtn" (click)="isAccntLocked(false)"></button>
      </div>
  
      <!-- going back for changing otp method -->
      <p *ngIf="enableOtp" (click)="prevScreen()" class="changeVerificationMethod">
        Change Verification Mode
      </p>
  
      <!-- directed from forget password -->
      <div class="validationContainer" *ngIf="throughForgetPassword">
        
        <!-- email validation -->
        <div class="mailContainer" *ngIf="!enableVerificationMethod" id="emailValidationContainer">
          <div class="mailInputContainer">
            <span style="margin-bottom: 0.5rem;">Email ID</span>
            <div class="mailInputSubContainer" [ngStyle]="{ 'border-color': !emailValidation ? 'red' : '#787885'}">
            <input type="email" [(ngModel)]="enteredMailId" placeholder="Enter your mail ID" (keyup)="verifyEmailFormat()" class="inputBox"/>
            <img src="../../../assets/errorIcon.svg" alt="error" *ngIf="!emailValidation" class="errorIcon">
            </div>
            <span class="errMsg" *ngIf="!emailValidation">{{validationMessage}}</span>
  
          </div>
  
          <button pButton type="button" label="Verify" (click)="validateEmail()" [ngClass]="{'enableBtn': !disableVerifyBtn, 'disableBtn': disableVerifyBtn}"></button>
        </div>
  
        <!-- otp sending method -->
        <div class="mailContainer" *ngIf="enableVerificationMethod && !enableOtp && !verifyOtpStatus" id="verificationMethodContainer">
          <div class="otpInputContainer">
            <span style="color: #333333; font-size: 1rem">Choose the OTP Verification mode</span><br />
  
            <div class="methodContainer">
              <p-radioButton name="group1" value="SMS" [label]="verificationMethodLabel[0]" [(ngModel)]="selectedOtpOption" inputId="opt1" (click)="checkVerificationMethod()"></p-radioButton>
              <p-radioButton name="group1" value="EMAIL" [label]="verificationMethodLabel[1]" [(ngModel)]="selectedOtpOption" inputId="opt2" (click)="checkVerificationMethod()"></p-radioButton>
            </div>
          </div>
          <button pButton type="button" label="Proceed" (click)="getOtp(false)" [disabled]="disableProceedBtn" [ngClass]="{'enableBtn': !disableProceedBtn, 'disableBtn': disableProceedBtn}"></button>
        </div>
  
        <!-- adding recieved otp -->
        <div class="otpContainer" *ngIf="enableOtp && !verifyOtpStatus" id="OtpContainer">
          <div class="otpInputContainer">
            <div class="otpTitleContainer">
              <span style="color: #333333; font-size: 1rem">Enter your 6- digit OTP</span>
              <span class="timer" [ngStyle]="{'color': counterMessage == 'Session Timed Out' ? 'red' : '#5CAEB7'}">{{counterMessage}}</span>
            </div>
  
            <div class="methodContainer">
              <div class="otp-input-fields">
                <input type="number" [disabled]="sessionTimeOut" (keyup)="changeOtpBox($event)" (focus)="checkOtpBoxIndex(1)" #otpBox_1/>
                <input type="number" [disabled]="sessionTimeOut" (keyup)="changeOtpBox($event)" (focus)="checkOtpBoxIndex(2)" #otpBox_2/>
                <input type="number" [disabled]="sessionTimeOut" (keyup)="changeOtpBox($event)" (focus)="checkOtpBoxIndex(3)" #otpBox_3/>
                <input type="number" [disabled]="sessionTimeOut" (keyup)="changeOtpBox($event)" (focus)="checkOtpBoxIndex(4)" #otpBox_4/>
                <input type="number" [disabled]="sessionTimeOut" (keyup)="changeOtpBox($event)" (focus)="checkOtpBoxIndex(5)" #otpBox_5/>
                <input type="number" [disabled]="sessionTimeOut" (keyup)="changeOtpBox($event)" (focus)="checkOtpBoxIndex(6)" #otpBox_6/>
              </div>
  
            </div>
          </div>
    
          <button pButton type="button" label="Verify" (click)="verifyOtp()" style="margin: 0" [disabled]="disableVerifyOtpBtn" [ngClass]="{'enableBtn': !disableVerifyOtpBtn, 'disableBtn': disableVerifyOtpBtn}"></button>
  
          <div class="resendOtpContainer">
            <p>Didn’t receive OTP?
              <span (click)="resendOtp($event)" [ngClass]="{'enableResendOtp': !(resendDisabled), 'disableResendOtp': (resendDisabled)}">{{resendMessage}}</span>
            </p>
          </div>
        </div>
  
        <!-- otp verify -->
        <div class="otpContainer" *ngIf="verifyOtpStatus" id="otpVerifyStatusContainer">
          <img [src]="otpVerified ? '../../../assets/tick.svg' : '../../../assets/cross.svg'" [alt]="otpVerified ? 'statusTrueImage' : 'statusFalseImage'"/>
          <p class="otpStatusContent" [innerHTML]="verifyStatusContent"></p>
          <button pButton type="button" [label]="otpVerified ? 'Proceed to login' : 'Retry'" (click)="otpVerifyStatusAction()" class="enableBtn" style="margin: 0"></button>
        </div>
      </div>
    </div>
  
    <div class="bottomSection">
      <img src="../../../assets/car-and-city-image.svg" class="footerImage" alt=""/>
    </div>
  </div>
  