
<!-- BASIC INPUT -->
<div class="basic-input" *ngIf="inputType == 'BASIC'">
<span class="field-label">
    <div class="field-label-container">
        {{inputBoxDetails ? inputBoxDetails.label : null}}
        <sup *ngIf="inputBoxDetails.required">*</sup>
    </div>
    
    <div class="tooltip-container">
        <custom-tooltip *ngIf="inputBoxDetails.enableTooltip" [tooltipText]="inputBoxDetails.tooltipContent"></custom-tooltip>
    </div>
    
</span>
<div class="input-container-basic" [ngClass]="{'input-error' : inputBoxDetails?.validation?.isError, 'input-success' : inputBoxDetails?.validation?.isSuccess, 'input-disabled' : inputBoxDetails.isDisabled}">
    <input [type]="inputBoxDetails.type" class="custom-input-basic" [placeholder]="inputBoxDetails.placeholder" [(ngModel)]="inputBoxDetails.inputValue" [disabled]="inputBoxDetails.isDisabled" [ngClass]="{'input-disabled' : inputBoxDetails.isDisabled}"
      (input)="sendInputData('BASIC')" >
    <img src="assets/clear-input-icon.svg" alt="" class="clear-cta" (click)="clearInput('BASIC')" *ngIf="!(inputBoxDetails.inputValue == null || inputBoxDetails.inputValue == '')">
</div>
    <div class="validator-container" *ngIf="inputBoxDetails.validation != null">
    <validator [validatorDetails]="inputBoxDetails.validation"></validator>
    </div>
</div>



<!--PHONE INPUT -->
<div class="basic-input" *ngIf="inputType == 'PHONE'">
    <span class="field-label">
        <div class="field-label-container">
            {{inputBoxDetails ? inputBoxDetails.label : null}}
            <sup *ngIf="inputBoxDetails.required">*</sup>
        </div>
        
        <div class="tooltip-container">
            <custom-tooltip *ngIf="inputBoxDetails.enableTooltip" [tooltipText]="inputBoxDetails.tooltipContent"></custom-tooltip>
        </div>
    </span>
    <div class="input-container-basic" [ngClass]="{'input-error' : inputBoxDetails?.validation?.isError, 'input-success' : inputBoxDetails?.validation?.isSuccess, 'input-disabled' : inputBoxDetails.isDisabled}">
        <div class="calling-code-list-container">
            <p-dropdown [options]="inputBoxDetails.callingCodesList" [(ngModel)]="inputBoxDetails.callingCodeValue" group="true"  appendTo="body" [styleClass]="'country-dropdown'"
            filter="true" filterBy="value,label" optionLabel="inputBoxDetails.callingCodeValue.label" #countryDropDown optionLabel="label" 
            (onChange)="clearInput('PHONE')">
               
            <ng-template let-country pTemplate="item">
                    <div class="items-container">
                        <div class="left-area">
                        <span>{{country.flag}}</span>
                        <span>{{country.label}}</span>
                        </div>

                        <span class="right-area">{{country.value}}</span>
                    </div>
                </ng-template>
            </p-dropdown>
        </div>

        <div (click)="openDropDown()" class="selected-item-container">
            <div class="flag">
            <span>{{inputBoxDetails?.callingCodeValue?.flag}}</span>
            <img src="assets/drop-arrow.svg" alt="">
            </div>
            <span class="callingcode">{{inputBoxDetails?.callingCodeValue?.value}}</span>
        </div>
        
        <input [type]="inputBoxDetails.inputType" class="custom-input-basic" [placeholder]="inputBoxDetails.placeholder" 
        (input)="sendInputData('PHONE')"
        [(ngModel)]="inputBoxDetails.inputValue" [disabled]="inputBoxDetails.isDisabled" [ngClass]="{'input-disabled' : inputBoxDetails.isDisabled}" [maxlength]="inputBoxDetails?.callingCodeValue?.limit">

        <img src="assets/clear-input-icon.svg" alt="" class="clear-cta" (click)="clearInput('PHONE')" *ngIf="!(inputBoxDetails.inputValue == null || inputBoxDetails.inputValue == '')">
    </div>
        <div class="validator-container" *ngIf="inputBoxDetails.validation != null">
        <validator [validatorDetails]="inputBoxDetails.validation"></validator>
        </div>
    </div>
    
    

    <!-- Google Places Input -->
    <div class="basic-input places-input" *ngIf="inputType == 'PLACES'">
        <span class="field-label-places">
            <div class="field-label-container">
                {{inputBoxDetails ? inputBoxDetails.label : null}}
                <sup *ngIf="inputBoxDetails.required">*</sup>
            </div>
            
            <div class="tooltip-container">
                <custom-tooltip *ngIf="inputBoxDetails.enableTooltip" [tooltipText]="inputBoxDetails.tooltipContent"></custom-tooltip>
            </div>
        </span>
        <div class="input-container-places" [ngClass]="{'input-error' : inputBoxDetails?.validation?.isError, 'input-success' : inputBoxDetails?.validation?.isSuccess, 'input-disabled' : inputBoxDetails.isDisabled}">
            <input [type]="inputBoxDetails.inputType" class="custom-input-basic" [placeholder]="inputBoxDetails.placeholder" [(ngModel)]="inputBoxDetails.inputValue.place" [disabled]="inputBoxDetails.isDisabled" [ngClass]="{'input-disabled' : inputBoxDetails.isDisabled}"
              (input)="sendInputData('PLACES')" [options]="inputBoxDetails.options" ngx-google-places-autocomplete (onAddressChange)="getLocation($event)">
            <img src="assets/clear-input-icon.svg" alt="" class="clear-cta" (click)="clearPlacesInput('PLACES')" *ngIf="!(inputBoxDetails.inputValue.place == null || inputBoxDetails.inputValue.place == '')">
        </div>

            <div class="validator-container" *ngIf="inputBoxDetails.validation != null">
            <validator [validatorDetails]="inputBoxDetails.validation"></validator>
            </div>
        
        <div class="landmark-container" *ngIf="inputBoxDetails.showLandmark">
            <span class="field-label">Suggested Places:</span>
            <div *ngFor="let landmark of inputBoxDetails.landmarks; index as i">
                <div [ngClass]="{'landmark-unselect': !inputBoxDetails.isLandmarkSelected[i], 'landmark-select' : inputBoxDetails.isLandmarkSelected[i]}" (click)="selectLandmark(i)">
                    <span>{{landmark.place}}</span>
                </div>
                
            </div>
        </div>

        </div>



    <!-- Calendar -->
     <div class="basic-input" *ngIf="inputType == 'CALENDAR'">
        <span class="field-label">
            <div class="field-label-container">
                {{inputBoxDetails ? inputBoxDetails.label : null}}
                <sup *ngIf="inputBoxDetails.required">*</sup>
            </div>
            
            <div class="tooltip-container">
                <custom-tooltip *ngIf="inputBoxDetails.enableTooltip" [tooltipText]="inputBoxDetails.tooltipContent"></custom-tooltip>
            </div>
        </span>
        <div class="input-container-calendar" [ngClass]="{'input-error' : inputBoxDetails?.validation?.isError, 'input-success' : inputBoxDetails?.validation?.isSuccess, 'input-disabled' : inputBoxDetails.isDisabled}">
            
            <p-calendar [(ngModel)]="inputBoxDetails.inputValue" [dateFormat]="inputBoxDetails?.format" [disabled]="inputBoxDetails?.isDisabled" appendTo="body" [ngStyle]="{width: '90%'}"
            [selectionMode]= "inputBoxDetails?.selection" [minDate]="inputBoxDetails?.minDate" [maxDate]="inputBoxDetails?.maxDate" [showTime]="inputBoxDetails?.showTime" (onShow)="datePanelVisible()"
             (onSelect)="selectDateHandler()" [stepHour]="inputBoxDetails?.stepHour" [stepMinute]="inputBoxDetails?.stepMinute" [placeholder]="inputBoxDetails?.placeholder"></p-calendar>
            
            <img src="assets/clear-input-icon.svg" alt="" class="clear-cta" (click)="clearInput('CALENDAR')" *ngIf="!(inputBoxDetails.inputValue == null || inputBoxDetails.inputValue == '')">
        </div>
            <div class="validator-container" *ngIf="inputBoxDetails.validation != null">
            <validator [validatorDetails]="inputBoxDetails.validation"></validator>
            </div>
        </div>
        