<div class="main-container">

    <div class="news-main-container">
    <div class="news-sub-container">    
    <div class="news-container" *ngFor="let news of newsDetails; index as i" [@slideInleft300]="animationArr[i]?.slide  == 'IN' ? '*' : 'void'" (click)="redirectTo(news?.redirectionUrl)"
                                                                             [@slideOutright300]="animationArr[i]?.slide  == 'OUT' ? 'void' : '*'"
    [ngStyle]="{'z-index': newsDetails.length - i, 'top': (i * 30) + 'px', 'transform': 'scale(' + (1 - i * 0.1) + ')'}">
        <img [src]="news.imgUrl" alt="">
        <span class="title">{{news?.title}}</span>
        <span class="text">{{news?.text}}</span>
    </div> 
    </div>

    <div class="news-controls">
        <img src="assets/backward.svg" alt="" (click)="slide('backward')">
        <img src="assets/forward.svg" alt="" (click)="slide('forward')">
    </div>
    
    </div>
</div>