import { Component, OnDestroy } from '@angular/core';
import { BreadCrumbAction, BreadCrumbType, StepperAlign, StepperData } from '../../types/components.type';
import { CommonService } from '../../getter-setter/common.service';
import { CreateRideService } from '../../getter-setter/container/create-ride/create-ride-container.service';
import { CountryFeatureListService } from '../../localization/country-feature-list/country-feature-list.service';
import { PaymentDetailsContainerService } from '../../getter-setter/container/create-ride/payment-details-container.service';
import { CustomerDetailsContainerService } from '../../getter-setter/container/create-ride/customer-details-container.service';

@Component({
  selector: 'app-create-ride-page',
  templateUrl: './create-ride-page.component.html',
  styleUrls: ['./create-ride-page.component.scss']
})
export class CreateRidePageComponent {
  

constructor(private _common: CommonService, 
            private _paymentDetails: PaymentDetailsContainerService,
            private _customerDetails: CustomerDetailsContainerService){
  
  this._common.setAllCarVariants();
  this._common.setDefaultAgmCoordinates();
  this._customerDetails.setDefaultCallingCode();
  this._paymentDetails.setAllowedPaymentModesDetails();
  }



  

}
