import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxOtpInputComponent, NgxOtpInputConfig } from 'ngx-otp-input';
import { NgxSpinnerService } from 'ngx-spinner';
import { elementAt } from 'rxjs';
import { AppService } from 'src/app/services/app.service';
import { UsersService } from 'src/app/services/users/users.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-multi-factor',
  templateUrl: './multi-factor.component.html',
  styleUrls: ['./multi-factor.component.css']
})
export class MultiFactorComponent implements OnInit {


  // flags
  title = '';
  content = '';
  showCredential = false;
  selectedCredentialValue = '+91-7895348660';
  selectedMethod = "";
  verificationMethodLabel = [];
  disableProceedCta = true;
  disableVerifyCta = true;
  maxTime = 60;
  timerValue = this.maxTime;
  userAccntDetails = {}
  credentials = {};
  resend = false;
  @ViewChild('ngxotp') ngxOtp: NgxOtpInputComponent;
  otp;
  changeModeFlag = false;
  showBackBtn = false;
  attemptsLeft = '';


  //otp input box configuration
  otpInputConfig: NgxOtpInputConfig = {
    otpLength: 6,
    pattern: /^\d+$/,
    autofocus: true,
    autoblur: true,
  };

  sessionOut = false;
  timerInterval = null;

  //localStorageData
  localStorageDto = {
    accessToken: '',
    businessAccountId: null,
  }

  
  screenNavigator = [{name: 'VERIFICATION_MODE', isActive: true, title: 'Two Factor Authorisation', showCredential: false, showBackBtn: true, backBtnRedirection: '/login', changeModeFlag: false, content: 'Multi-factor authentication safeguards your account for enhanced security'},
                     {name: 'VERIFICATION_MAIL', isActive: false, title: 'We sent a code to your email', showCredential: true, showBackBtn: true, backBtnRedirection: 'VERIFICATION_MODE', changeModeFlag: true, content: 'Enter the 6-digit verification code sent to'},
                     {name: 'VERIFICATION_MOBILE', isActive: false, title: 'We sent a code to your mobile number', showCredential: true, showBackBtn: true, backBtnRedirection: 'VERIFICATION_MODE', changeModeFlag: true, content: 'Enter the 6-digit verification code sent to'},
                     {name: 'INCORRECT_OTP', isActive: false, title: 'Entered incorrect OTP', showCredential: false, showBackBtn: false, backBtnRedirection: 'VERIFICATION_MODE', changeModeFlag: false, content: ''}]



 constructor(private _users: UsersService, private _app: AppService, private router:Router){
  this.changeTitleAndContent()
  this.userAccntDetails = this._app.getAccountDetails();
  this.credentials = this._app.getCredentials();
  this.verificationMethodLabel = [`via Mobile Number (${this.userAccntDetails['callingCode']} - ${this.userAccntDetails['maskedPhone']})`, `via Email (${this.userAccntDetails['maskedEmail']})`]
 }

 ngOnInit(): void {
  
 }

 changeTitleAndContent(){
  this.screenNavigator.forEach(element=>{
    if(element.isActive){
      this.title = element.title,
      this.content = element.content
      this.showCredential = element.showCredential
      this.changeModeFlag = element.changeModeFlag
      this.showBackBtn = element.showBackBtn
    }
  })
 }

 clearTitleAndContent(){
  this.screenNavigator.forEach(element=>{
    element.isActive = false;
  })
 }

 selectVerificationMethod(){
  this.disableProceedCta = this.selectedMethod == '' ? true : false;  
}

getOtpScreen(){
  this.clearTitleAndContent();
  this.selectedMethod == 'SMS' ? this.screenNavigator[2]['isActive'] = true : this.screenNavigator[1]['isActive'] = true;
  this.selectedCredentialValue = this.selectedMethod == 'SMS' ? `${this.userAccntDetails['callingCode']} - ${this.userAccntDetails['maskedPhone']}` : `${this.userAccntDetails['maskedEmail']}`
  clearTimeout(this.timerInterval)
  this.timer(this.maxTime)
  this.resend = false;
  this.changeTitleAndContent();
  this.sendOtp();
  
}

resendOtp(event){
  if(this.timerValue != 0){
    event.preventDefault()
  }
  else{
    this.resend = true
    this.sendOtp();
    this.timer(this.maxTime)
  }
}


timer(timerValue){
  this.timerValue = timerValue;
  
  this.timerInterval = setInterval(()=>{
    if(timerValue == 0){
      this.sessionOut = true;
      clearTimeout(this.timerInterval)
    }
    else{
      --timerValue;
      this.sessionOut = false;
    }
    this.timerValue = timerValue;
  },1000)

}

sendOtp(){
  let sendOtpDto = {
    username: this.credentials['username'] == 'admin' ? this.userAccntDetails['email'] : this.credentials['username'],
    notificationChannel: this.selectedMethod,
    resendOtp : this.resend ? true : false
  }
  this._app.sendMfaOtp(sendOtpDto).subscribe(res=>{
    if(res['statusCode'] >= 300){
      Swal.fire({
        icon: 'error',
        title: 'Technical Error!',
        text: res['message']
      })
    }
    else{
      return ;
    }})
}


handleFillEvent(value){
  this.otp = value; 
}

handleChangeEvent(value){
  this.disableVerifyCta = value.includes(null) || value.includes('')? true : false;
}


verifyOtp(){
  let verifyOtpDto = {
    username : this.credentials['username'] == 'admin' ? this.userAccntDetails['email'] : this.credentials['username'],
    password: this.credentials['password'],
    otp: this.otp
  }

  this._app.verifyMfaOtp(verifyOtpDto).subscribe(res=>{
    if(res['statusCode']>= 300){
      this._app.setLoginAttempts(res['message'])
      if(res['message'] == 'Unsuccessful attempts reached, please reset your password'){  
        this.router.navigateByUrl('/resetpwd?accntLocked=true')
      }
      else{
      this.clearTitleAndContent()
      this.screenNavigator[3]['isActive'] = true;
      this.attemptsLeft = res['message'].split(" ")[7];
      this.changeTitleAndContent();
      }
    }
    else{
    this.localStorageDto = {
      accessToken : res['response']['access_token'],
      businessAccountId: res['response']['businessAccountIds'][0]
    }
    this._app.setValuesInLocalStorage(this.localStorageDto);
  }})
}


changeVerificationMode(){
  this.clearTitleAndContent();
  this.screenNavigator[0].isActive = true;
  this.changeTitleAndContent();
}

prevScreen(){
  this.screenNavigator.forEach(element=>{
    if(element.isActive){
      if(element.name == 'VERIFICATION_MODE')
        this.router.navigateByUrl(`${element.backBtnRedirection}`)

      else{
        let redirectionScreenName = element.backBtnRedirection;
          this.clearTitleAndContent();
          this.screenNavigator.forEach(elem=>{
            elem.isActive = elem.name == redirectionScreenName ? true : false;
          })
          this.changeTitleAndContent();
      }
    }
})
}


}

