import { Type } from "@angular/core"
import { Location } from "./container.type"

export type BreadCrumbAction = {
    type: string,
    item: string
}

export const BreadCrumbType = {
        ADD : 'ADD',
        REMOVE: 'REMOVE'
}

export type StepperData = {
    label: string,
    isComplete: boolean,
    isActive: boolean,
    caption?: string,
    isDisabled: boolean
}

export const StepperAlign = {
    HORIZONTAL : 'HORIZONTAL',
    VERTICAL : 'VERTICAL'
}

export type InfoType = {
    isError?: boolean,
    isSuccess?: boolean,
    message?: string
}

export type CustomInputType = {
  placeholder : string,
  type: string,
  inputValue: string | null,
  label: string,
  name: string
  validation: InfoType | null,
  isDisabled: boolean,
  required: boolean
  enableTooltip: boolean,
  tooltipContent: string | Type<any> 
}

export type PlacesInputType = {
    placeholder : string,
    type: string,
    inputValue: Location | null,
    label: string,
    name: string,
    validation: InfoType | null,
    isDisabled: boolean,
    options : Object,
    isRequired? : Boolean ,
    landmarks?: {lat: number, lng: number, place: string}[],
    isAirportEnabled?: Boolean,
    isAirportLocation? :Boolean,
    isLandmarkEnabled? :Boolean,
    showLandmark?: Boolean, 
    zoneId: number;
    isLandmarkSelected?: Boolean[] 
    required:boolean,
    enableTooltip: boolean,
    tooltipContent: string | Type<any> 
  }



export type CustomDropDown = {
    label: string,
    value: string,
    flag?: any,
    limit?: number,
    additionaltext?:string,
    
}


export type CustomDropDownInputType= {
    callingCodesList?: any[],
    callingCodeValue : CustomDropDown | null
    inputValue: string | null ,
    limit: number | null,
    validation: InfoType | null,
    label: string,
    name: string,
    placeholder : string,
    type: string,
    isDisabled: boolean,
    required: boolean,
    enableTooltip: boolean,
    tooltipContent: string | Type<any> 
}

export type CustomButton = {
    label: string,
    left: boolean,
    right: boolean,
    leftUrl: string,
    rightUrl: string,
    disabled: boolean
}


export type AccordianDetails = {
    label : string,
    isActive: boolean,
    isComplete : boolean,
    isVisible :boolean
    isDisabled: boolean,
}

export type TextAreaDetails = {
    placeholder : string,
    rows? : string,
    columns? : string,
    inputValue: string | null ,
    label: string,
    name: string
    validation: InfoType | null,
    isDisabled: boolean,
    enableTooltip: boolean,
    required: boolean
    tooltipContent: string | Type<any> 
}

export type DropdownDetails = {
    selectedValue: CustomDropDown,
    options: CustomDropDown[],
    validation: InfoType | null,
    label: string,
    name: string,
    placeholder : string,
    isDisabled: boolean,
    required: boolean
    
}


export type CustomDropDownType ={
    selectedValue: CustomDropDown | null,
    options: CustomDropDown[],
    validation: InfoType | null,
    label: string,
    name: string,
    placeholder : string,
    isDisabled: boolean,
    required: boolean,
    enableTooltip: boolean,
    tooltipContent: string | Type<any> 
}

export type CalendarType = {
 
        placeholder : string,
        inputValue: Date | null,
        label: string,
        name: string
        validation: InfoType | null,
        isDisabled: boolean,
        format: string,
        selection?: string,
        minDate? :Date,
        maxDate? : Date,
        showTime? : boolean,
        timeOnly? : boolean
        stepMinute?: number,
        stepHour?: number,
        required: boolean,
        enableTooltip: boolean,
        tooltipContent: string | Type<any> 
      
}

export type EditableContainerType = {
    isEdit: boolean,
    placeholder : string,
    type: string,
    inputValue: string | null,
    label: string,
    name: string
    validation: InfoType | null,
    isDisabled: boolean
}


export type SubPlacesApiDto = {
    id: number,
    displayName: string,
    placeName: string,
    placeAddress: string,
    latitude: number,
    longitude: number,
    placeId: null,
    parentPlaceId: string,
    count: null,
    type: string,
    currentLocation: boolean
}


export type FetchLandmarksApiResponse = {
    lat: number,
    lng: number,
    locationType: string,
    subPlacesDto: {
        subPlaces: SubPlacesApiDto[]
        },
    zoneId: number,
    leadTimeInMillis: number,
    is_airport: boolean
}


export type MapDto ={
    coordinates: Location | PlacesInputType[] | null,
    type: string
}

export type PopupType ={
    theme : string,
    title: string,
    message: string,
    ctaLabel: string,
}

export const Theme = {
    ERROR : 'ERROR',
    WARNING: 'WARNING',
    INFO: 'INFO',
    SUCCESS: 'SUCCESS'
}

export type checkboxType = {
    checked: boolean,
    disabled: boolean
}

export type SlideCarouselData = {
    url: string,
    title: string,
    text: string
}

export const CarouselAlignmnet = {
    VERTICAL : 'VERTICAL',
    HORIZONTAL: 'HORIZONTAL'
}