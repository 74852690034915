import { Injectable } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { CountryFeatureListService } from 'src/app/app-v2/localization/country-feature-list/country-feature-list.service';
import { RequestRideApiResponse } from 'src/app/app-v2/types/apis.type';

@Injectable({
  providedIn: 'root'
})
export class PaymentDetailsContainerService {

  rideRequestDetails: RequestRideApiResponse 
  allowedPaymentModesDetails = {};
  allowedPaymentModes: string[] = [];



  constructor(private _countryFeature: CountryFeatureListService, private _loader: NgxSpinnerService) { }


  setAllowedPaymentModes(paymentModes){
    this.allowedPaymentModes = paymentModes;
  }

  getAllowedPaymentModes(){
    return this.allowedPaymentModes;
  }

  setAllowedPaymentModesDetails(){

    this.allowedPaymentModesDetails = {};
    let allPaymentModes = this._countryFeature.getCountryFeatureList()['defaultPaymentModes'];
    
      Object.keys(allPaymentModes).forEach(paymentMode=>{
      if(this.allowedPaymentModes.includes(paymentMode)){
        this.allowedPaymentModesDetails[paymentMode] = allPaymentModes[paymentMode];
      }
      else{
        return;
      }
    })
  }

  getAllowedPaymentModesDetails(){
    return this.allowedPaymentModesDetails; 
  }


  getRideRequestDetails(){
    return this.rideRequestDetails;
  } 

  setRideRequestDetails(rideRequestDetails){
    this.rideRequestDetails = rideRequestDetails;  
  }

 

}
