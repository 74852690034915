import { CommonModule } from '@angular/common';
import { AfterContentInit, AfterViewChecked, AfterViewInit, Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { left } from '@popperjs/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { DropdownModule } from 'primeng/dropdown';
import { API_URL_REDIRECT_TO_RAZORPAY } from 'src/app/config/apiRouteConfig';
import { CommonsModule } from 'src/app/main/common/common.module';
import { CurrencySymbolPipe } from 'src/app/main/common/pipes/pipe.pipe';
import { AppService } from 'src/app/services/app.service';
import { BusinessDetailsService } from 'src/app/services/business-details/business-details.service';
import { PaymentService } from 'src/app/services/payment/payment.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-payment-dialog',
  templateUrl: './payment-dialog.component.html',
  styleUrls: ['./payment-dialog.component.css'],
  standalone: true,
  imports: [CommonModule, DropdownModule, FormsModule, CommonsModule]
})
export class PaymentDialogComponent implements OnChanges, OnInit{
  
  @Input() pendingPaymentProp = {};
  
  paymentList = [{'code': 'CASH', 'name': 'Cash', img: '../../../../../assets/arrow.svg'}];
  paymentMethodsNotAllowed;
  selectedPaymentMethod;
  totalPayment = 0;
  selectedCountry = 'IN'
  isError = false;
  errorMessage = ""
  addMoney = false;
  interval;
  currencyPipe;
constructor(private _businessService: BusinessDetailsService, private _appService: AppService, private _paymentService: PaymentService, private loader: NgxSpinnerService){
}



  ngOnChanges(changes: SimpleChanges): void {
    this.totalPayment = this.pendingPaymentProp['totalAmount'];
  }

  ngOnInit(){
    this.currencyPipe = new CurrencySymbolPipe(this._appService);
    this.clearData()
    this.selectedCountry = this._appService.getCountry();
    this.paymentMethodsNotAllowed = this.selectedCountry == 'IN'  ? ['PREPAID', 'CASH', 'CREDIT_CARD', 'PREPAID_CARD'] : ['CASH', 'BUSINESS_ACCOUNT', 'BLU_WALLET']
    this.getPaymentMethods();
  }

  getPaymentMethods(){
    let filteredPaymentMethods = [];
      filteredPaymentMethods = this._businessService.getAcceptedPaymentType().filter((val=> {
        return !this.paymentMethodsNotAllowed.includes(val);
      }));

      this.paymentList = filteredPaymentMethods.map((value, index)=>{
        switch(value){
          case "BUSINESS_ACCOUNT":
            return {'code': value, 'name': `Business Wallet - ${this.currencyPipe.transform(localStorage.getItem('walletBalance'))}`, img: '../../../../../assets/walletIcon.svg'};    
          case "PREPAID":
            return {'code': value, 'name': 'Prepaid', img: '../../../../../assets/payViaCardIcon.svg'};
          case "CREDIT_CARD":
            return {'code': value, 'name': 'Pay via Card', img: '../../../../../assets/payViaCardIcon.svg'};   
          default:
            break; 
        }
      })

      this.selectedPaymentMethod = this.selectedCountry == 'AE' ? {'code': 'PREPAID', 'name': 'Prepaid', img: '../../../../../assets/payViaCardIcon.svg'} : {'code': '', 'name': '', img: ''};
      console.log(this.selectedPaymentMethod)
  }

  confirmPayment(){
    console.log(this.selectedPaymentMethod)
    switch(this.selectedPaymentMethod.code){
      case "BUSINESS_ACCOUNT":
        this.getPaymentFromBusinessWallet();
        break;
      case "PREPAID":
        this.getPaymentFromTeller();  
    }
  }


  getPaymentFromBusinessWallet(){
    this.isError = false;
    this.addMoney = false;
   let  paymentOrderId = {'paymentOrderIds': this.pendingPaymentProp['paymentOrderId']}
   this.loader.show();
    this._paymentService.getPaymentFromWallet(paymentOrderId).subscribe((res)=>{
      this.loader.hide();
      if(res['statusCode'] >= 300){
        this.isError = true;
        this.errorMessage = res['message']
        this.addMoney = res['message'] === 'Insufficient amount in Business Wallet.' ? true : false
      }

      else{
        Swal.fire({
          icon: 'success',
          title: 'Success',
          text: 'Payment Completed'
        })
      }
    })
  }


  getPaymentFromTeller(){
    this.isError = false;
    this.addMoney = false;
    let leftAmount = 0;
    let paymentOrderId = {'paymentOrderIds': this.pendingPaymentProp['paymentOrderId']};
    this.loader.show();
    this._paymentService.getPaymentFromPrePaid(paymentOrderId).subscribe((res)=>{
      this.loader.hide();
      if(res['statusCode'] >= 300){
        this.isError = true;
        this.errorMessage = res['message']
      }
      else{
                let url= res['response']['redirectionUrl'];
                let paymentIdForTeller = res['response']['paymentOrderId'];
                let newWindow = window.open(url,"Add Money","toolbar=no,scrollbars=no,location=no,statusbar=no,menubar=no,resizable=0,width=300,height=300,left = 490,top = 262");
                this.keepEye(newWindow, paymentIdForTeller,leftAmount, 'PREPAID');
      }
    })
  }


  addMoneyToWallet(){
    let leftAmount = 0;
    leftAmount = this.getLeftAmount();
    this.loader.show()
    this._paymentService.addMoneyToWallet(leftAmount).subscribe(res=>{
      this.loader.hide();
      if (res['statusCode'] >= 300) {
        Swal.fire({
          title: 'Error',
          icon: 'info',
          text: res['message']
        });
      } else {
         let paymentId= res['response'];
         let newWindow = window.open(API_URL_REDIRECT_TO_RAZORPAY+paymentId+'?phoneNo='+localStorage.getItem('phoneNo') +'&email='+localStorage.getItem('email')+'',"Add Money","toolbar=no,scrollbars=no,location=no,statusbar=no,menubar=no,resizable=0,width=300,height=300,left = 490,top = 262");
         this.keepEye(newWindow, paymentId, leftAmount, 'BUS_WALLET');
      }
    })

    
  }


  clearData(){
    console.log('data cleared')
    this.selectedPaymentMethod;
    this.selectedCountry = 'IN'
    this.isError = false;
    this.errorMessage = ""
    this.addMoney = false;
  }


  getLeftAmount(){
    let walletBalance = +localStorage.getItem('walletBalance');
    let leftAmount = walletBalance > this.totalPayment ? walletBalance - this.totalPayment : this.totalPayment - walletBalance;
      return leftAmount;
  }


  keepEye(window:Window, paymentId, leftAmount, paymentType){
    this.loader.show();
    this.interval = setInterval(()=> {
       if(window.closed){
         this._paymentService.checkStatus(paymentId).subscribe((response:any)=>{
          this.loader.hide();
           if(response.status>=300){
            Swal.fire({
              title: 'Error',
              icon: 'info',
              text: response.message
            });
            clearInterval(this.interval); 
           }

           if(response.response=='COMPLETED'){
            if(paymentType == 'BUS_WALLET'){
             let message= (this._appService.getCountry() == 'IN' ?  "₹" +leftAmount   : leftAmount  +'د.إ' )+ " has been added to business wallet."
            Swal.fire({
              title: '<span style="color:#5DCF5E">Payment Successful</span>',
              imageUrl:'../../../../../assets/addMoneySuccess.png',
              text: message
            }).then((res)=>{
              this.getPaymentFromBusinessWallet();
            });
            let previousBalance = this._businessService.getWalletBalamce();
            let newBalance = parseInt(previousBalance) + leftAmount;
            this._businessService.setWalletBalance(newBalance);
            clearInterval(this.interval); 
           }}

           else if(paymentType == 'PREPAID'){
            Swal.fire({
              title: '<span style="color:#5DCF5E">Payment Successful</span>',
              imageUrl:'../../../../../assets/addMoneySuccess.png',
              text: "Payment Succesful!"
            })
            clearInterval(this.interval); 
           }
           
          
           else {
            Swal.fire({
              title: 'Info',
              icon: 'info',
              text: "Transaction is in Status: "+ response.response
            });
            clearInterval(this.interval); 
           }
         })
         
       }
  },1000);
    
   }
  
}
