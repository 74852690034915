<div class="main-container">

    <div class="location-container">
        <div class="add-location-container">
            <add-location-container></add-location-container>
        </div>

        <div class="additional-details-container">
            <additional-details-container></additional-details-container>
        </div>
        
        

        <div class="location-cta-container">
            <custom-button [buttonDetails]="locationContainerCta" (onCustomButtonClick)="submitLocationDetails()"></custom-button>
        </div>   
        
    </div>

    
    <div class="map-container">
        <custom-map [agmCoordinates]="agmCoordinates" [isDropRequired]="isDropRequired" [isVisible]="isShowMapMarkers" mapType='LOCATION'></custom-map>
    </div>
    
    

</div>