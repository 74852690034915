
import { FormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HelpRoutingModule } from './help-routing.module';
import {CommonsModule} from '../../common/common.module';
import { HelpComponent } from './help.component';




@NgModule({
  declarations: [HelpComponent],
  imports: [
    
    CommonModule,
    NgbModule,
    FormsModule,
    CommonsModule,
    HelpRoutingModule
  ],
})
export class HelpModule { }
