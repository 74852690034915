import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild } from '@angular/core';
import { CustomDropDown, CustomDropDownType, DropdownDetails } from '../../types/components.type';
import { Dropdown } from 'primeng/dropdown';

@Component({
  selector: 'dropdown',
  templateUrl: './dropdown.component.html',
  styleUrls: ['./dropdown.component.scss'],
  
})
export class DropdownComponent implements OnChanges{
  @Input() dropdownDetails: CustomDropDownType;
  @Output() changehandler= new EventEmitter<void>();


ngOnChanges(changes: SimpleChanges): void {
    
}


handleChangeEvent(){
  this.changehandler.emit();
}
}

