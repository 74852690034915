import { Component, Input, NgZone, OnInit, Output, ViewChildren, EventEmitter } from '@angular/core';
import { MapsAPILoader } from "@agm/core";
import { data }  from '../../../rides/lngLatMap'
import { AppService } from 'src/app/services/app.service';


@Component({
  selector: 'app-g-place-input',
  templateUrl: './g-place-input.component.html',
  styleUrls: ['./g-place-input.component.css']
})
export class GPlaceInputComponent implements OnInit {

  flagToCheckPickupState: boolean = true;
  lat = 0;
  lng = 0;
  zoom = 10;
  disableInput=false;
  origin = { lat: 0, lng: 0 };
  @ViewChildren('stop') public stop;
  @Input() stopObject:any;
  @Input() index:number;
  @Output() resetForm = new EventEmitter<boolean>();
  location:any ;
  constructor(private mapApiLoader: MapsAPILoader,private ngZone: NgZone, private appService:AppService) { }

  ngOnInit() {
    this.location = this.stopObject.place;
    this.disableInput = this.stopObject.disabled?this.stopObject.disabled:false;
  }

  ngAfterViewInit() {

    // console.log(this.fromLocation)
     
    this.stop.first.nativeElement.value = this.location;
    this.mapApiLoader.load().then(() => {
      let bound1 = data[1];
      let newArray = [];

      for (let i = 0; i < bound1.length; i++) {
        newArray.push(
          new google.maps.LatLng(bound1[i].latitude, bound1[i].longitude)
        );
      }

      let defaultBounds = new google.maps.LatLngBounds(...newArray);
      let autocomplete = new google.maps.places.Autocomplete( this.stop.first.nativeElement,{
        fields: [
          "address_component",
          "adr_address",
          "formatted_address",
          "geometry",
          "place_id"
        ],
        componentRestrictions: { country: this.appService.getCountry() },
        bounds: defaultBounds
      });
        
      autocomplete.setBounds(defaultBounds);

      autocomplete.addListener("place_changed", () => {
        this.ngZone.run(() => {
          this.flagToCheckPickupState = true
          let place: google.maps.places.PlaceResult = autocomplete.getPlace();
          this.stopObject.place = place.formatted_address;
          if (place.geometry === undefined || place.geometry === null) {
            return;
          }
          
            this.stopObject.lat= place.geometry.location.lat(),
            this.stopObject.lng= place.geometry.location.lng()
            this.stopObject.position = this.index;
       
          this.zoom = 12;
        });        
      });

           
      
    });
  }

  resetSubmitForm(){
   this.resetForm.emit(false);
  }
  resetLatLong(){
    this.stopObject.lat= 0;
    this.stopObject.lng= 0;

  }

}
