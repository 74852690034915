import { Component, EventEmitter, Input, OnChanges, OnDestroy, Output, SimpleChanges } from '@angular/core';
import { BreadCrumbAction } from '../../types/components.type';

@Component({
  selector: 'breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss']
})
export class BreadcrumbComponent implements OnChanges, OnDestroy{

  items: string[]=[];
  lastIndex: number;

  @Input() action: BreadCrumbAction;
  @Output() getLabel: EventEmitter<string> = new EventEmitter();
           
  
  constructor(){
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(this.action){
        if(this.action['type'] == "ADD" && !this.items.includes(this.action['item'])){
          this.addItem(this.action.item)
        }
        else
        {
          this.removeItems(this.action.item)
        }
      }

     else{
      return null;
     }   
  }

  addItem(item){
    this.items.push(item);
    this.setLastIndex();
  }

  removeItems(item){
    let index = this.items.indexOf(item);
    this.items.splice(index+1);
    this.setLastIndex();
  }

  setLastIndex(){
    this.lastIndex = this.items.length-1;
  }

  getItem(item){
    this.getLabel.emit(item);
  }

  ngOnDestroy(): void {
    this.items = [];
  }


}
