<div class="main-container">
    <div class="useful-links" *ngFor="let link of usefulLinks; index as i">
        <div class="useful-links-caption-container">
            <span class="title">{{link.title}}</span>
            <span class="useful-link-text">{{link.text}}</span>    
        </div>

        <span class="link-container" (click)="openLink(i)">Open now</span>
    </div>

</div>