import { Injectable } from '@angular/core';
import { featureList as featureListIN } from '../countries/IN/IN-feature-list';
import { featureList as featureListAE } from '../countries/AE/AE-feature-list';
import { count } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CountryFeatureListService {

  constructor() {}

  countryFeatureList = {};

  async setCountryFeatureList(country){
    
    switch(country){
      case 'IN': 
      this.countryFeatureList = await featureListIN;
      break;

      case 'AE': 
      this.countryFeatureList = await featureListAE;
      break;
    }
  }

  getCountryFeatureList(){
    return this.countryFeatureList;
  }
}
