<div class="success-container info-align" *ngIf="validatorDetails.isSuccess">
<img src="assets/success-validator.svg" alt="error-icon">
<p>{{validatorDetails.message}}</p>
</div>


<div class="error-container info-align" *ngIf="validatorDetails.isError">
        <img src="assets/error-validator.svg" alt="success-icon">
        <p>{{validatorDetails.message}}</p>
</div>
        