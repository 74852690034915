import { Injectable } from '@angular/core';
import OktaAuth from '@okta/okta-auth-js';

@Injectable({
  providedIn: 'root'
})
export class OctaService {
  private oktaAuth: OktaAuth;

  constructor() { 
     this.oktaAuth = new OktaAuth({
      issuer: 'https://dev-16508575.okta.com',
      clientId: '0oajdsl84sQ2hxWQ75d7',
      redirectUri: window.location.origin + '/callback',
    });
  }

  async login() {
    await this.oktaAuth.signInWithRedirect();
  }

  async handleAuthentication() {
    await this.oktaAuth.handleLoginRedirect();
    const user = await this.oktaAuth.getUser();
    console.log('User: ', user);
  }

  async isAuthenticated(): Promise<boolean> {
    return this.oktaAuth.isAuthenticated();
  }

}
