import { trigger, state, style, animate, transition } from '@angular/animations';


export function fadeInCarousel(duration){
    return trigger(`fadeIn`,[
        state('void', style({ opacity: 0.4 })),
        state('*', style({ opacity: 1 })),
    
        transition('void => *', [
          animate(`${duration}ms ease-out`)
        ]),
    ]);
  }
  
  
  export function fadeOutCarousel(duration){
    return trigger(`fadeOut`, [
        state('*', style({ opacity: 1 })),
        state('void', style({ opacity: 0.4 })),
    
        transition('* => void', [
          animate(`${duration}ms ease-out`)
        ]),
    ]);
  }

  export function slideLeft(){
    
    return trigger('slideLeft', [
      transition('* => *', [
        animate('0.5s ease-in-out', style({ transform: `transformValue` }))
        ], 
        { params: { transformValue: 'translateX(0%)' } })
    ])
  }