
    <div class="map-container" *ngIf="mapType == 'LOCATION'">
        <agm-map [zoom]="8" [maxZoom]="20" [disableDefaultUI]="true" class="google-map" [styles]="agmCredentials.options">
            <agm-direction [renderOptions]="agmCredentials.renderOptions" [origin]="agmCredentials.pick" [destination]="agmCredentials.destination"
            [markerOptions] = "agmCredentials.markerOptions" [waypoints]="agmCredentials.stops">
            </agm-direction>    
        </agm-map>
    </div>


    <div class="map-container" *ngIf="mapType == 'BOUNDS'">
        <agm-map [zoom]="10" [disableDefaultUI]="true" class="google-map" [styles]="agmCredentials.options" [latitude]="defaultCoordinates.lat" [longitude]="defaultCoordinates.lng">
            
            <!-- For Dashed Stroke -->
            <agm-polyline *ngFor="let pickArr of regionCoordinates.pick">  
                <agm-polyline-point *ngFor="let pointLatLng of pickArr" [latitude]="pointLatLng.lat"  [longitude]="pointLatLng.lng">
                </agm-polyline-point>
                <agm-icon-sequence [strokeColor]="'#2C66E3'" [repeat]="'12px'"  [strokeWeight]="1"  [scale]="2" [strokeOpacity]="100"  [path]="'M 0,-1 0,1'"></agm-icon-sequence>
            </agm-polyline>
            
            <!-- For Fill area -->
            <agm-polygon [paths]="regionCoordinates.pick" [strokeColor]="'transparent'" [strokeOpacity]="1.0" [fillColor]="'#D9EBFF'" [fillOpacity]="0.35"></agm-polygon>


            <agm-polyline *ngFor="let dropArr of regionCoordinates.drop">  
                <agm-polyline-point *ngFor="let pointLatLng of dropArr" [latitude]="pointLatLng.lat"  [longitude]="pointLatLng.lng">
                </agm-polyline-point>
                <agm-icon-sequence [strokeColor]="'#E43B3B'" [repeat]="'14px'"  [strokeWeight]="1"  [scale]="2" [strokeOpacity]="100"  [path]="'M 0,-1 0,1'"></agm-icon-sequence>
            </agm-polyline>
            
            <!-- For Fill area -->
            <agm-polygon [paths]="regionCoordinates.drop" [strokeColor]="'transparent'" [strokeOpacity]="1.0" [fillColor]="'#F3C2C299'" [fillOpacity]="0.35"></agm-polygon>

          </agm-map>
    </div>