import { trigger, state, style, animate, transition } from '@angular/animations';


export function slideAnimIn(direction, timeSpan){
    return trigger(`slideIn${direction}${timeSpan}`, [

        state('void', style({ 
            transform: direction == 'up' || direction == 'down' ? 
              `translateY(${direction == 'up' ? '-100%' : '100%'})`
            : `translateX(${direction == 'right' ? '100%' : '-100%'})`, 
            opacity: 0 })),
    
        state('*', style({ 
                transform: 'translateX(0)', 
                opacity: 1 })),
    
        transition('void => *', [
          animate(`${timeSpan}ms ease-out`)
        ]),
      ]);

}


export function slideAnimOut(direction, timeSpan){
    return trigger(`slideOut${direction}${timeSpan}`, [

        state('*', style({ 
            transform: 'translateX(0)',     
            opacity: 1 
        })),

                
        state('void', style({ 
            transform: direction == 'up' || direction == 'down' ? 
            `translateY(${direction == 'up' ? '-100%' : '100%'})`
          : `translateX(${direction == 'right' ? '100%' : '-100%'})`, 
            opacity: 0 })),                

        transition('* => void', [
          animate(`${timeSpan}ms ease-out`)
        ]),
      ]);

}

export function fadeAnimIn(timeSpan){
    return trigger(`fadeIn${timeSpan}`, [
        state('void', style({ opacity: 0 })),
        state('*', style({ opacity: 1 })),
    
        transition('void => *', [
          animate(`${timeSpan}ms ease-out`)
        ]),
    ]);
}



export function fadeAnimOut(timeSpan){
    return trigger(`fadeOut${timeSpan}`, [
        state('*', style({ opacity: 1 })),
        state('void', style({ opacity: 0 })),
    
        transition('* => void', [
          animate(`${timeSpan}ms ease-out`)
        ]),
    ]);
}


