import { Component, Input, Type } from '@angular/core';

@Component({
  selector: 'custom-tooltip',
  templateUrl: './tooltip.component.html',
  styleUrls: ['./tooltip.component.scss']
})
export class TooltipComponent {
@Input() tooltipText: string | Type<any>
}
