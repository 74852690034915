import { Component, Input } from '@angular/core';
import { InfoType } from '../../types/components.type';

@Component({
  selector: 'validator',
  templateUrl: './input-validator.component.html',
  styleUrls: ['./input-validator.component.scss']
})
export class InputValidatorComponent {
@Input() validatorDetails: InfoType;

}
