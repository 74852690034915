import { Component } from '@angular/core';
import { CommonSubscriptionService } from '../../subscriptions/common/common-subscription.service';
import { Theme } from '../../types/components.type';
import { CommonService } from '../../getter-setter/common.service';

@Component({
  selector: 'select-ride-type-page',
  templateUrl: './select-ride-type-page.component.html',
  styleUrls: ['./select-ride-type-page.component.scss']
})
export class SelectRideTypePageComponent {

  constructor(private _common: CommonService){
    _common.setAllowedRideTypesDetails();
  }

  
}
