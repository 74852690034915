<div class="main-container">
    <span class="location-title">Add Location</span>

    <div class="pickup-container">
        <div class="image-container">
            <img src="assets/pick.svg" alt="">
        </div>
        
        <div class="input-container">
            <custom-input inputType="PLACES" [inputBoxDetails]="locationInputs.PICK" (onSendLocationData)="getLocationDataFromInput($event, 'PICKUP')"></custom-input>
        </div>
        
    </div>

    <div class="stop-container" *ngFor="let stop of locationInputs.STOPS; index as i">
        <div class="image-container">
            <span>{{i + 1}}</span>
        </div>
        
        <div class="stop">
            <custom-input inputType="PLACES" [inputBoxDetails]="locationInputs.STOPS[i]" (onSendLocationData)="getLocationDataFromInput($event, 'STOPS', i)"></custom-input>
        </div>
        <img src="assets/delete-icon.svg" alt="" (click)="deleteStop(i)">
    </div>

    <div class="add-stop-container" (click)="addStop(locationInputs.STOPS.length)" *ngIf="isAddStop">
        <img src="assets/add-icon.svg" alt="">
        <span>Add Stop</span>
    </div>

    
    <div class="drop-container" *ngIf="locationInputs.DROP.isRequired">
        <div class="image-container">
            <img src="assets/drop.svg" alt="">
        </div>

        <div class="input-container">
            <custom-input inputType="PLACES" [inputBoxDetails]="locationInputs.DROP" (onSendLocationData)="getLocationDataFromInput($event, 'DROP')"></custom-input>
        </div>
    </div>

</div>