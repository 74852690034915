     <div class="mainContainer">
        <h1>Your Go-To Guide for Common Queries</h1>

        <div class="contentContainer">
        <div class="textContainer">
        <p>Here, we've compiled a comprehensive collection of answers to address the most frequently encountered questions and concerns. Whether you're new to our ride booking platform or a seasoned user, this resource is designed to provide you with quick and accurate solutions.</p>
        <p>From understanding our booking process and navigating the dashboard's features to managing your account and resolving payment inquiries, we've got you covered. We believe in ensuring your ride booking experience is smooth and hassle-free, and our FAQs aim to empower you with the information you need.</p>
       
        <a><button (click)="download()">Download Product Guide</button></a>
        
        </div>
            <img src="../../../../assets/helpImage.svg" alt="">
        </div>
    </div>
