import { Component, Input, OnChanges, OnInit } from '@angular/core';

@Component({
  selector: 'useful-links-container',
  templateUrl: './useful-links-container.component.html',
  styleUrls: ['./useful-links-container.component.scss']
})
export class UsefulLinksContainerComponent implements OnChanges {
  
  @Input() usefulLinks = [{title: 'Product Guide - Symphony', text: 'Comprehensive guide to all the features available on the dashboard', redirectionUrl: ''},
                          {title: 'Product Guide - Symphony', text: 'Comprehensive guide to all the features available on the dashboard', redirectionUrl: ''}
  ];
  
  
  ngOnChanges(): void {
  
  }

  openLink(index){
    window.open(this.usefulLinks[index].redirectionUrl, "_blank");
  }

}
