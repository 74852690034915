export const environment = {
  production: false,
  baseUrl: 'https://symphony.tracking.dev.blucgn.com',
  ticket: 'https://incident.tracking.dev.blucgn.com/ticket-management-system',
  cookieURL: 'dev.blucgn.com',
  cookieSuffix:'Dev',
  AESKey: '674cdc23d0c1e136a093c8724efc7bd4',
  googlePlacesAPIKey:"AIzaSyBv4EsNYBQ1J5YBKKu30ZM5nuOe9EMNzyc",
  razorpayKey: 'rzp_test_iFrNnuoZWwkM1P'
};
