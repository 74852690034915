import { API_URL_LIST_VEHICLE, API_URL_ADD_VEHICLE, API_URL_EDIT_VEHICLE, API_URL_GET_VEHICLE_REGISTRATION, API_URL_LIVE_MAP, API_URL_VEHICLE_SANITIZATION_TIME } from '../../config/apiRouteConfig/index';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
@Injectable()
export class VehicleService {

  callListApi = true;

  constructor(private _http: HttpClient) { }
  getVehicles(): Observable<any> {
    return this._http
      .get(API_URL_LIST_VEHICLE);
  }
  // fetchThisVehicle(vehicleId){
  //   return this._http.get();
  // }
  addVehicle(data): Observable<any> {
    return this._http.post(`${API_URL_ADD_VEHICLE}`, data);
  }

  editVeihicle(data): Observable<any> {
    return this._http.put(`${API_URL_EDIT_VEHICLE}/${data.vehicleNumber}`, data);
  }

  getVehicleDetails(data) {
    return this._http.get(`${API_URL_GET_VEHICLE_REGISTRATION}vehicleNumber=${data}`)
  }
  getAllVehicles() {
    return this._http.get(`${API_URL_LIST_VEHICLE}`)
  }
  //  getAllDummyVehicles(){
  //   return this._http.get(`${DUMMY_API_URL_LIST_VEHICLE}`)
  // }
  getAllDetailsForLiveMap() {
    return this._http.get(`${API_URL_LIVE_MAP}`)
  }
  getvehicleSanitizationTime(): Observable<any> {
    return this._http.get(`${API_URL_VEHICLE_SANITIZATION_TIME}`)
  }
}
