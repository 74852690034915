import { Pipe, PipeTransform } from '@angular/core';
import { AppService } from 'src/app/services/app.service';

@Pipe({
  name: 'dateformat'
})
export class DatePipe implements PipeTransform {

  transform(value: any, ...args: any[]): any {
    if (!value) {
      return '';
    }
    const datesVal = value.split('/');
    let temp = datesVal[0]; 
    datesVal[0] = datesVal[1];
    datesVal[1] = temp;
    return datesVal.join(' ');
  }

}

@Pipe({
  name: 'datetimeformat'
})
export class DateTimePipe implements PipeTransform {

  transform(value: any, ...args: any[]): any {
    if (!value) {
      return '';
    }

    if(typeof(value) == 'string'){
      return value;
    }
    
    let localDate = new Date(value);
    const formattedDate = localDate.toLocaleString('en-US', {
      year: 'numeric',
      month: 'short', // Short month name (e.g., Aug)
      day: '2-digit', // Two-digit day (e.g., 29)
      hour: '2-digit', // Two-digit hour (e.g., 11)
      minute: '2-digit', // Two-digit minute (e.g., 43)
      second: '2-digit', // Two-digit second (e.g., 43)
      hour12: true, // Use 12-hour clock (AM/PM)
    });
    value = formattedDate
    
    const datesVal = value.split(',');
    let date = datesVal[0];
    let year = datesVal[1];
    let time = datesVal[2] ? datesVal[2] : datesVal[1].split("at")[1];
    let splittedTime = time.split(':');
    splittedTime[2]= splittedTime[2].slice(-2);
    
    if(args.includes('WEEK_SCHEDULE')){
      return splittedTime[0]+':' +splittedTime[1] +' ' +splittedTime[2];  
    }
    else{
    return date+year+','+splittedTime[0]+':' +splittedTime[1] +' ' +splittedTime[2];
    }
  }

}

@Pipe({
  name: 'onlyDateFormat'
})
export class OnlyDatePipe implements PipeTransform {

  transform(value: any, ...args: any[]): any {
    if (!value) {
      return '';
    }
    
    const datesVal = value.split(',');
    
    let date = datesVal[0];
    let year = datesVal[1];
    let time = datesVal[2];
    let splittedTime = time.split(':');
    splittedTime[2]= splittedTime[2].slice(-2);
    
    return date;
  }

}

@Pipe({
  name: 'timediff'
})
export class TimeDifference implements PipeTransform {
  transform(minorTime = Date.now() ) {
    if(!minorTime){
      minorTime = Date.now();
    }
    var  majorTime = Date.now();
    let diff = ( Math.floor((majorTime - minorTime) / 1000) ); //seconds

    var time = ' ago';
    if(diff<0){
      time = '';
    }
    diff = Math.abs(diff);
    if (diff < 60) {
      return  diff + ' sec' + time;
    }
    else if (diff >= 60 && diff < 3600) {
      let minute = Math.floor(diff / 60);
      return  minute + ' min' + time;
    }
    else {

      let minute = Math.floor(diff/60);
      //console.log('minute',minute);

      let hour = Math.floor(minute/60);
      //console.log('hours',hour);

      minute = Math.floor(minute - hour*60);
      //console.log('minute',minute);
      return  hour + ' hr ' + minute + ' min' + time;
    }
  }
}

@Pipe({
  name : 'maskNumber'
})
export class MaskNumber implements PipeTransform{
  transform(number: any, maskfromLastIndex: any) {
    let numberArr = number.split("");
    let mask = [];
    for(let i = 0 ; i < numberArr.length ; i++){
      if(i < (numberArr.length - maskfromLastIndex)){
        mask.push(numberArr[i]);
      }
      else{
        mask.push("x");
      }
    }

    return mask.join("");
    
  }
}
@Pipe({
  name: 'currencySymbol'
})
export class CurrencySymbolPipe implements PipeTransform {
  constructor(private appService:AppService) {}

  transform(value: any, currencyCode: string = null): string {
    const userCurrencyCode = currencyCode || this.getUserCurrencyFromLocale();
    switch(userCurrencyCode){
      case "AED": return value + "AED";
      case "INR": return  "₹" +value;
    }
    return value;
  }

  public getUserCurrencyFromLocale(): string {
    // You can implement logic here to determine the user's currency code from their locale.
    // For demonstration purposes, we'll use 'USD' as the default currency code.
    switch(this.appService.getCountry()){
      case "AE": return "AED";
      case "IN": return "INR";
    }
  }
}

@Pipe({
  name : 'timeonly'
})
export class TimeOnlyPipe implements PipeTransform{
  transform(time: any) {
    let getHour = new Date(time).getHours();
    let getMin = new Date(time).getMinutes();
    let editedTime = `${getHour}:${getMin}`;

    return editedTime;
    
  }
}

@Pipe({
  name: 'paymentModeSetter'
})
export class PaymentModeSetter implements PipeTransform {
  constructor(private appService:AppService) {}

  transform(value: string): string {
    if(this.appService.getCountry() == 'AE' && value == 'BUS_WALLET'){
    return "POSTPAID";
    }
    return value;
  }
}

@Pipe({
  name: 'taxNames'
})
export class TaxNamePipe implements PipeTransform {
  transform(country) {
    switch(country){
      case 'IN':
        return 'GST'
      case 'AE':
        return 'VAT'
    }
  }
}
