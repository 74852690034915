export const featureList = {'IN' : {
    liveRide: {
        rentalPackageClassic: true,
        rentalPackagePremium: true
    },
    scheduledRide: {
        rentalPackageClassic: true,
        rentalPackagePremium: true
    },
    pastRide: {
        rentalPackageClassic: true,
        rentalPackagePremium: true
    },
    payments: {
        walletTransaction: true,
        postPaidTransaction: false,
        downloadInvoice: true,
        creditHistory: true,
        prepaidTransaction: false,

    },
    createRide:{
        tncExpand : true,
        slots: {
            'SCHEDULED_RIDE' : false,
            'SCHEDULED_RENTALS' : {
                'CLASSIC' : false,
                'PREMIUM' : true
            }
        }
    }

},

'AE' : {
    liveRide: {
        rentalPackageClassic: false,
        rentalPackagePremium: true
    },
    scheduledRide: {
        rentalPackageClassic: false,
        rentalPackagePremium: true
    },
    pastRide: {
        rentalPackageClassic: false,
        rentalPackagePremium: true
    },
    payments: {
        walletTransaction: true,
        postPaidTransaction: false,
        downloadInvoice: true,
        creditHistory: true,
        prepaidTransaction: true,
    },
    createRide:{
        tncExpand : false,
        slots: {
            'SCHEDULED_RIDE' : true,
            'SCHEDULED_RENTALS' : {
                'CLASSIC' : true,
                'PREMIUM' : true
            }
        }
    }
}
}